import { makeAPIClient } from "@/main.js"

export async function loadVersions(host, id, username) {
  const api = await makeAPIClient(host)
  console.log("-------------------------------", id)
  const { data: versions } = await api.get(`/valuation/GetDcfOverridesRIM?security_id=${id}&username=${username}`)
  return versions
}

export async function saveOverride(host, overrides) {
  var postBody = Object.fromEntries(
    Object.entries(overrides).map(([k, v]) => {
      return [k.replace("Override", ""), v]
    })
  )

  const api = await makeAPIClient(host)
  return await api.post(`/valuation/AddUpdateOverrideRIM`, postBody)
}

export async function deleteOverride(host, dcf_override_rim_id) {
  const api = await makeAPIClient(host)
  return await api.get(`/valuation/DeleteOverrideRIM?dcf_override_rim_id=` + dcf_override_rim_id)
}
