import _ from "lodash"
import IRR from "./IRR.js"

const seq = (len,base=0) => Array(len).fill(0).map((_d, i) =>base+ i) //eslint-disable-line
const fullYears = seq(11)
const fyears = seq(10).map((i) => i + 1)
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0)
const CJ2 = (a, b) => [].concat(...a.map((a) => b.map((b) => [].concat(a, b))))
const CJ = (a, b, ...c) => (b ? CJ(CJ2(a, b), ...c) : a)
const smallCurrencies = ["KRW", "IDR", "VND"]

function slope(yArr, xArr) {
  const len = xArr.length
  const xMean = arrSum(xArr) / len
  const yMean = arrSum(yArr) / len
  return (
    arrSum(seq(len).map((i) => (yArr[i] - yMean) * (xArr[i] - xMean))) /
    arrSum(seq(len).map((i) => (xArr[i] - xMean) ** 2))
  )
}

function calcLTG(d, field, years) {
  const ys = years.map((y) => {
    const dp = d[`${field}FY${y}`]
    return (dp > 0 ? 1 : -1) * Math.log(Math.abs(dp))
  })
  return Math.E ** slope(ys, years) - 1
}

function getDiscFactor(y, KE, month) {
  return (1 + KE) ** -(y - 1 + month / 12)
}

function calcIntrinsicValue(d, KE, TVGro) {
  const riFY10 = d.niFY9 - KE * d.BVFY9
  const tv = (riFY10 * (1 + TVGro)) / (KE - TVGro)

  return fyears.reduce((acc, y) => {
    const discFactor = getDiscFactor(y, KE, d.month)
    const ri = d[`niFY${y}`] - KE * d[`BVFY${y - 1}`]

    if (y === 10) {
      acc += ri * discFactor + tv * discFactor
    } else {
      acc += ri * discFactor
    }
    return acc
  }, d.BVFY0)
}

export default function process(raw) {
  const d = _.cloneDeep(raw)

  //data already return in thousands, 1e3 is million
  //for small currencies, convert it to billions
  d.scaleMultiple = smallCurrencies.includes(d.I_CURR) ? 1e3 : 1

  //will use BV to calculate
  delete d["TermVal.FY10"]
  ;["KE", "eq.tcap"].forEach((f) => {
    d[`${f}Original`] = d[f]
    delete d[f]
  })

  fullYears.forEach((y) => {
    d[`BVFY${y}`] = d[`BV${y}`]
    delete d[`BV${y}`]
    ;["rev", "llp", "ni", "BV", "opr"].forEach((s) => {
      d[`${s}FY${y}Original`] = d[`${s}FY${y}`]
      d[`gro${s}FY${y}Original`] =
        y === 0 ? NaN : (d[`${s}FY${y}Original`] - d[`${s}FY${y - 1}Original`]) / Math.abs(d[`${s}FY${y - 1}Original`])
      delete d[`gro${s}FY${y}`]
      delete d[`${s}FY${y}`]
    })

    //need to provide original value for overridables here
    //this also used to prefill other fileds depending on this
    d[`taxRateFY${y}Original`] = d[`taxFY${y}`] / (d[`oprFY${y}Original`] - d[`llpFY${y}Original`])
    d[`costToIncFY${y}Original`] = 1 - d[`oprFY${y}Original`] / d[`revFY${y}Original`]

    delete d[`taxFY${y}`]
  })

  //replace null or 0  to NaN to get rid of Infinity when devided by 0
  for (let key of Object.keys(d)) {
    if (d[key] === null) d[key] = NaN
    if (key.startsWith("revFY") && d[key] === 0) d[key] = NaN
    if (key.startsWith("niFY") && d[key] === 0) d[key] = NaN
  }

  const extended = applyGettersSetters(d)

  const originalsToSaveFromGetters = [
    ...CJ(["rev", "opr", "BV", "ni"], [5, 10]).map(([item, y]) => `LTG_${item}_${y}y`),
    "pctOfValueTV",
    "pctOfValueCFs",
    "Term.Growth.vs.Current.Growth",
    "intrinsicValue",
    "intrinsicPrice",
    "upsideDownside",
    "P_CLOSE",
    "IRR"
  ]

  originalsToSaveFromGetters.forEach((field) => {
    extended[`${field}Original`] = extended[field]
  })

  return extended
}

function defineWritablePropertyDesc(d, f) {
  if (!Array.isArray(f)) f = [f]
  f.forEach((f) => {
    Object.defineProperty(d, f, {
      get() {
        return d[`${f}Override`] !== undefined ? d[`${f}Override`] : d[`${f}Original`]
      },
      set(value) {
        d[`${f}Override`] = value
      }
    })
  })
}

export function applyGettersSetters(d) {
  const assumptions = ["KE", "eq.tcap", "TVGro"]
  d.TVGroOriginal = 0
  const gros = CJ(["rev", "llp", "BV"], fullYears).map(([item, y]) => `gro${item}FY${y}`)
  const taxRates = fullYears.map((y) => `taxRateFY${y}`)
  const costToInc = fullYears.map((y) => `costToIncFY${y}`)

  defineWritablePropertyDesc(d, assumptions)
  defineWritablePropertyDesc(d, gros)
  defineWritablePropertyDesc(d, taxRates)
  defineWritablePropertyDesc(d, costToInc)

  const Od = (field, description) => Object.defineProperty(d, field, description)

  fullYears.forEach((y) => {
    ;["rev", "BV", "llp"].forEach((s) => {
      Od(`${s}FY${y}`, {
        get() {
          if (!isNaN(d[`gro${s}FY${y}`])) {
            return (1 + d[`gro${s}FY${y}`]) * d[`${s}FY${y - 1}`]
          } else {
            return d[`${s}FY${y}Original`] //need a start value here
          }
        }
      })
    })

    //net profit
    Od(`niFY${y}`, {
      get() {
        return d[`profitBeforeTaxFY${y}`] * (1 - d[`taxRateFY${y}`])
      }
    })

    // this is not overridable but just showing the rate of change
    Od(`groniFY${y}`, {
      get() {
        return (d[`niFY${y}`] - d[`niFY${y - 1}`]) / Math.abs(d[`niFY${y - 1}`])
      }
    })

    //ppop
    Od(`oprFY${y}`, {
      get() {
        return d[`revFY${y}`] - d[`opExpFY${y}`]
      }
    })

    Od(`opExpFY${y}`, {
      get() {
        return d[`revFY${y}`] * d[`costToIncFY${y}`]
      }
    })

    Od(`profitBeforeTaxFY${y}`, {
      get() {
        return d[`oprFY${y}`] - d[`llpFY${y}`]
      }
    })

    Od(`taxFY${y}`, {
      get() {
        return d[`taxRateFY${y}`] * d[`profitBeforeTaxFY${y}`]
      }
    })

    Od(`riFY${y}`, {
      get() {
        if (y === 0) return NaN
        return d[`niFY${y}`] - d[`BVFY${y - 1}`] * d.KE
      }
    })

    Od(`roeFY${y}`, {
      get() {
        return d[`niFY${y}`] / d[`BVFY${y}`]
      }
    })

    Od(`epsFY${y}`, {
      get() {
        return d[`niFY${y}`] / d.Shares
      }
    })

    Od(`bvpsFY${y}`, {
      get() {
        return d[`BVFY${y}`] / d.Shares
      }
    })

    Od(`repsFY${y}`, {
      get() {
        if (y === 0) return NaN
        return d[`epsFY${y}`] - d.KE * d[`bvpsFY${y - 1}`]
      }
    })

    Od(`netCFFY${y}`, {
      get() {
        if (y === 0) return d.bvpsFY0 - d.P_CLOSE
        else if (y === 10) return d[`repsFY10`] + d.TVPS
        else return d[`repsFY${y}`]
      }
    })
  })

  CJ(["rev", "opr", "BV", "ni"], [5, 10]).forEach(([item, y]) => {
    Od(`LTG_${item}_${y}y`, {
      get() {
        return calcLTG(d, item, seq(y + 1))
      }
    })
  })

  Od("pctOfValueCFs", {
    get() {
      const cfSum = arrSum(fyears.map((y) => d[`niFY${y}`] * getDiscFactor(y, d.KE, d.month)))
      return cfSum / (cfSum + d.TV * getDiscFactor(10, d.KE, d.month))
    }
  })

  Od("pctOfValueTV", {
    get() {
      return 1 - d.pctOfValueCFs
    }
  })

  Od("Term.Growth.vs.Current.Growth", {
    get() {
      return d.TVGro - (d.riFY2 - d.riFY1) / Math.abs(d.riFY1)
    }
  })

  Od("TV", {
    get() {
      return ((d.niFY10 - d.KE * d.BVFY9) * (1 + d.TVGro)) / (d.KE - d.TVGro)
    }
  })

  Od("TVPS", {
    get() {
      return d.TV / d.Shares
    }
  })

  Od("intrinsicValue", {
    get() {
      return calcIntrinsicValue(d, d.KE, d.TVGro)
    }
  })

  Od("intrinsicPrice", {
    get() {
      return d.intrinsicValue / d.Shares
    }
  })

  Od("upsideDownside", {
    get() {
      return d.intrinsicPrice / d.P_CLOSE - 1
    }
  })

  Od("discountScenarios", {
    get() {
      return seq(5).map((v) => d.KE + 0.01 * (v - 2))
    }
  })

  Od("TVGroScenarios", {
    get() {
      return seq(5).map((v) => d.TVGro + 0.01 * (v - 2))
    }
  })

  Od("upsideDownsideAnalysis", {
    get() {
      const senarios = CJ(d.discountScenarios, d.TVGroScenarios)
      return senarios.map((s) => {
        const [KE, TVGro] = s
        const scenarioIntrinsicValue = calcIntrinsicValue(d, KE, TVGro)
        return (scenarioIntrinsicValue / d.Shares / d.P_CLOSE) * d.scaleMultiple - 1
      })
    }
  })

  Od("IRR", {
    get() {
      return IRR(fullYears.map((y) => d[`netCFFY${y}`]))
    }
  })

  return d
}
