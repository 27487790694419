<template>
  <div
    class="override-input"
    :style="{ textAlign }"
    :class="{ 'input-value-changed': valueUpdated }"
    tabindex="0"
    @keyup.tab.capture.exact="focused = true"
    @keyup.tab.shift.exact="shiftTab"
    @click="focused = true"
  >
    <div v-show="focused" style="height: inherit">
      <input
        ref="inputEl"
        class="input--number"
        :class="{ 'input--percent': this.type === 'percent' }"
        type="number"
        step="any"
        :value="value"
        :placeholder="placeholder"
        @input="update"
        @blur="blurCheck"
        @focus="focusCheck"
        :min="min"
        :max="max"
      />
      <template v-if="value !== '' || focused">
        <span class="suffix" v-if="type === 'percent'">%</span>
        <span class="suffix" v-if="type === 'multiples'">x</span>
      </template>
    </div>

    <div v-if="!focused" class="override-value-formated" @click="focused = true">
      <span v-if="showInit || valueUpdated">
        {{
          fmtNum(value / (type === "percent" ? 100 : 1), digits, type === "percent") + (type === "multiples" ? "x" : "")
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { debounce } from "lodash"
import tippy from "tippy.js"

export default {
  props: {
    input: { type: Object, required: true },
    digits: { type: Number, default: 0 },
    field: { type: String, required: true },
    type: { type: String, default: "percent" },
    showInit: { type: Boolean, default: false },
    showPlaceholder: { type: Boolean, default: true },
    min: { type: Number, default: -1 * Number.MAX_VALUE },
    max: { type: Number, default: Number.MAX_VALUE },
    textAlign: { type: String, default: "right" }
  },
  data() {
    return {
      placeholder: null,
      value: null,
      focused: false
    }
  },
  mounted() {
    this.init()
    this.$watch("focused", (v) => {
      if (v) {
        const el = this.$refs.inputEl
        el.select()
      }
    })
  },
  beforeUpdate() {
    this.init()
  },
  computed: {
    valueUpdated() {
      return this.rawDisplayValue !== this.roundDecimal(this.value, this.digits) && this.value !== ""
    },
    multiples() {
      return this.type === "percent" ? 100 : 1
    },
    rawValue() {
      const original = this.input[this.field + "Original"]
      //there is no orignal for not overridable field
      const raw = this.input[this.field]
      if (original !== undefined) {
        return original
      }
      return raw
    },
    rawDisplayValue() {
      return isNaN(this.rawValue) ? "" : this.roundDecimal(this.rawValue * this.multiples, this.digits)
    }
  },
  methods: {
    // we are similating the tab behavior on  overrideInput component,
    // shift+tab can not be captured on the current instance,
    // so here use the global selector to decide which is the compnent shall gain focus
    // CONS:
    // this might result some unexpected tab behavior if mulitple type of input/tabbable element on the page
    // this is acceptable for our page here
    shiftTab(event) {
      const el = event.currentTarget
      if (el.classList.contains("override-input")) {
        const all = document.querySelectorAll(".override-input")
        const curOrder = [...all].indexOf(el)
        const prev = curOrder <= 1 ? 0 : curOrder - 1
        all[prev].click()
      }
    },
    init() {
      const v = this.input[this.field + "Override"]
      if (v === "" || v === undefined) {
        if (this.showInit) {
          this.value = isNaN(this.rawValue) ? "" : this.roundDecimal(this.rawValue * this.multiples, this.digits)
        } else {
          this.value = ""
        }
      } else {
        this.value = isNaN(v) ? "" : this.roundDecimal(v * this.multiples, this.digits)
      }
      this.placeholder = this.showPlaceholder ? this.rawDisplayValue : ""
    },
    roundDecimal(v, digits = 2) {
      return Math.round(v * 10 ** digits) / 10 ** digits
    },
    focusCheck($event) {
      this.focused = true
      const el = $event.currentTarget
      if (el._tippy) {
        el._tippy.hide()
      }
    },
    blurCheck($event) {
      this.focused = false
      let v = $event.target.valueAsNumber
      if (Number.isNaN(v) || $event.target.value === this.rawDisplayValue) {
        this.$emit("updateData", { [this.field]: undefined })
        if (this.showInit) {
          this.value = ""
        }
      } else {
        const el = this.$refs.inputEl
        // NO Elegant way to inject the logic here with dynamic validation rules referencing current WACC
        if (this.field === "impliedPerpetualGrowth" && v > this.input.WACC * 100) {
          this.$emit("updateData", { [this.field]: undefined })
          tippy(el, {
            content: "Perpetual Growth override can not exceed WACC",
            arrow: true,
            trigger: "manual",
            placement: "bottom",
            hideOnClick: false,
            onShow(tip) {
              setTimeout(() => tip.hide(), 2500)
            }
          })
          el._tippy.show()
        }
      }
    },
    update: debounce(function($event) {
      // disable native redo undo for input
      if ($event.inputType === "historyUndo" || $event.inputType === "historyRedo") {
        return
      }

      //give a pause for minus value input and clear input
      if ($event.data === "-" || $event.target.value === "") {
        return
      }

      let v = (this.value = $event.target.valueAsNumber)
      v = v / this.multiples

      if (Number.isNaN(v)) {
        v = undefined
      }
      this.$emit("updateData", { [this.field]: v })
    }, 200)
  }
}
</script>

<style scoped lang="scss">
.override-input {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #fffed5;
  height: 24px;
  line-height: 24px;

  .override-value-formated {
    width: 100%;
    height: inherit;
    line-height: inherit;
    text-align: inherit;
  }

  &.input-value-changed {
    text-shadow: 0px 0px 1px currentColor;
    color: blue;
  }

  input {
    color: inherit;
    text-align: inherit;
    text-shadow: inherit;
    width: 100%;
    height: 24px;
    background-color: #ffffd1;
    border: none;
    outline: none;
  }

  .input--percent {
    padding-right: 0.6em;
  }

  span.suffix {
    height: 24px;
    line-height: 24px;
    display: none;
    position: absolute;
    right: 0.4em;
    top: 0;
    pointer-events: none;
  }

  span.suffix {
    display: block;
  }
  input::placeholder {
    font-weight: 300 !important;
    opacity: 0.5;
  }
}
</style>
