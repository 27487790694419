<template>
  <table class="summary-table model-input-table">
    <thead>
      <tr>
        <th>Model Outputs</th>
        <th style="width: 90px">Default</th>
        <th style="width: 90px">Adjusted</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(m, index) in modelOutputs" :key="index">
        <td>{{ m.label }}</td>
        <td class="numeric-val" :style="{}">
          {{ fmtNum(input[m.name + "Original"], index > 2 ? 2 : 0, m.percent) }}
        </td>
        <td
          class="numeric-val"
          :style="{
            background: m[4] ? colorize(m[2], m[4]) : '#fff'
          }"
        >
          <TweenableNum :value="input[m.name]" :digits="index > 2 ? 2 : 0" :isPercent="m.percent"></TweenableNum>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import "@/scss/site.scss"
import { scaleLinear } from "d3-scale"

import TweenableNum from "@/components/common/TweenableNum.vue"

export default {
  components: { TweenableNum },
  props: {
    input: { type: Object, required: true }
  },
  data() {
    return {
      modelOutputs: [
        { label: "Intrinsic Value", name: "intrinsicValue" },
        { label: "Intrinsic Price", name: "intrinsicPrice" },
        { label: "Current Price", name: "P_CLOSE" },
        { label: "Upside/Downside", name: "upsideDownside", percent: true },
        { label: "RIM IRR", name: "IRR", percent: true }
      ]
    }
  },
  methods: {
    colorize(v, range) {
      if (v <= range[0]) return "#e77170"
      if (v >= range[1]) return "#7abc80"
      return scaleLinear()
        .domain(range)
        .range(["#e77170", "#dfff00", "#7abc80"])(v)
    }
  }
}
</script>

<style lang="scss" scoped>
.model-input-table {
  tr:nth-child(even) td {
    border-bottom: 1px solid #999;
  }
}
</style>
