var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.input)?_c('div',{staticClass:"d-flex dcf-toolbar align-center py-1"},[_c('v-btn',{attrs:{"text":"","small":"","disabled":!_vm.canUndo},on:{"click":_vm.undo}},[_c('v-icon',[_vm._v("mdi-undo")])],1),_c('v-btn',{attrs:{"text":"","small":"","disabled":!_vm.canRedo},on:{"click":_vm.redo}},[_c('v-icon',[_vm._v("mdi-redo")])],1),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.reload}},[_c('v-icon',[_vm._v("mdi-refresh")]),_vm._v(" Reload ")],1),(!_vm.showSaveTitleInput)?_c('v-btn',{staticStyle:{"transition":"margin-left 0.2s ease-in 0.3s"},style:({ 'margin-left': _vm.showSaveTitleInput ? '-138px' : 0 }),attrs:{"text":"","small":"","disabled":!_vm.override},on:{"click":_vm.startSave}},[_c('v-icon',[_vm._v("mdi-content-save")]),_vm._v(" Save As ")],1):_vm._e(),(!_vm.showSaveTitleInput)?_c('v-btn',{staticStyle:{"transition":"margin-left 0.2s ease-in 0.3s"},style:({ 'margin-left': _vm.showSaveTitleInput ? '-138px' : 0 }),attrs:{"text":"","small":"","disabled":_vm.currentVersion === '' || !_vm.userCanEditDelete},on:{"click":function($event){return _vm.save(_vm.currentVersion, true)}}},[_c('v-icon',[_vm._v("mdi-content-save-edit")]),_vm._v(" Update ")],1):_vm._e(),(_vm.showUpdateConfirmation)?_c('div',{staticClass:"caption green--text accent-3 mx-2 d-flex align-center",staticStyle:{"height":"28px","white-space":"nowrap"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]),_vm._v(" Override update saved successfully. ")],1):_vm._e(),(_vm.showSaveTitleInput)?_c('div',{staticClass:"mr-2",staticStyle:{"height":"28px","width":"210px","overflow":"hidden","transition":"opacity 1s cubic-bezier(0.4, 1, 0.4, 1)"},style:({
      opacity: _vm.showSaveTitleInput || _vm.showAcceptConfirmation ? 1 : 0
    })},[_c('div',{style:({
        transition: 'margin-top 0.3s ease-out',
        'margin-top': _vm.showAcceptConfirmation && _vm.titleInvalid ? '-58px' : _vm.showAcceptConfirmation ? '-29px' : '0'
      })},[_c('div',{staticClass:"d-flex px-2"},[_c('v-text-field',{ref:"titleEl",staticClass:"custom-title-input",attrs:{"dense":"","outlined":"","hide-details":"","autofocus":"","placeholder":"Enter title to save","rules":[_vm.versionNameRule],"autocomplete":"off"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.acceptTitle($event)}},model:{value:(_vm.savedVersion),callback:function ($$v) {_vm.savedVersion=$$v},expression:"savedVersion"}}),_c('v-btn',{staticClass:"px-0",attrs:{"icon":"","small":"","color":"primary"},on:{"click":_vm.acceptTitle}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1),_c('v-btn',{staticClass:"px-0",attrs:{"icon":"","small":"","color":"error"},on:{"click":_vm.resetSave}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1)],1),(_vm.showAcceptConfirmation)?_c('div',{staticClass:"caption green--text accent-3 mx-2 d-flex align-center",staticStyle:{"height":"28px","white-space":"nowrap"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-check")]),_vm._v(" Override saved successfully. ")],1):_vm._e(),(_vm.showAcceptConfirmation && _vm.titleInvalid)?_c('div',{staticClass:"caption: red--text accent-3 mx-2 d-flex align-center",staticStyle:{"height":"28px","white-space":"nowrap"}},[_c('v-icon',{attrs:{"color":"orange"}},[_vm._v("mdi-info")]),_vm._v(" The given name is invalid. ")],1):_vm._e()])]):_vm._e(),_c('v-spacer'),_c('select',{staticClass:"custom-select mr-2",staticStyle:{"width":"184px"},domProps:{"value":_vm.currentVersion},on:{"change":function($event){return _vm.switchVersion($event.target.value)}}},[_c('option',{attrs:{"value":""}},[_c('span',[_vm._v("Select from "+_vm._s(_vm.versionList.length)+" Override")]),(_vm.versionList.length > 0)?_c('span',[_vm._v("s")]):_vm._e()]),_vm._l((_vm.versionList),function(ref,index){
      var name = ref.name;
      var lastModified = ref.lastModified;
      var analyst = ref.analyst;
return _c('option',{key:index,domProps:{"value":name}},[_vm._v(" ["+_vm._s(_vm.formatDate(lastModified))+"] - "+_vm._s(name)+" - "+_vm._s(analyst)+" ")])})],2),_c('v-btn',{attrs:{"text":"","small":"","disabled":!_vm.currentVersion || _vm.versionList.length === 0 || !_vm.userCanEditDelete},on:{"click":_vm.deleteVersion}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete")])],1),_c('SimpleFeedbackButton'),_c('v-btn',{attrs:{"text":"","small":""},on:{"click":_vm.printWindow}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-printer")])],1),_c('v-btn',{attrs:{"text":"","small":"","target":"_blank","href":(_vm.publicPath + "files/WACC DCF Model Methodology.pdf")}},[_c('v-icon',[_vm._v("mdi-download")]),_vm._v(" Methodology ")],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }