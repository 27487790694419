import _ from "lodash"
import IRR from "./IRR.js"

const seq = (len, base = 0) => Array(len).fill(0) .map((_d, i) => base + i) //eslint-disable-line
const fullYears = seq(16).map((i) => i - 5)
const average = (array) => array.reduce((a, b) => a + b) / array.length
const arrSum = (arr) => arr.reduce((a, b) => a + b, 0)
const CJ2 = (a, b) => [].concat(...a.map((a) => b.map((b) => [].concat(a, b))))
const CJ = (a, b, ...c) => (b ? CJ(CJ2(a, b), ...c) : a)
const zeroFallback = (v) => (isNaN(v) ? 0 : v ?? 0)

function slope(yArr, xArr) {
  const len = xArr.length
  const xMean = arrSum(xArr) / len
  const yMean = arrSum(yArr) / len
  return (
    arrSum(seq(len).map((i) => (yArr[i] - yMean) * (xArr[i] - xMean))) /
    arrSum(seq(len).map((i) => (xArr[i] - xMean) ** 2))
  )
}

function calcLTG(d, field, years) {
  const ys = years.map((y) => {
    const dp = d[`${field}FY${y}`]
    return (dp > 0 ? 1 : -1) * Math.log(Math.abs(dp))
  })
  return Math.E ** slope(ys, years) - 1
}

function getTermFactor(WACC, multiple) {
  return (1 + WACC) * multiple - 1
}

function getDiscFactor(WACC, month, y) {
  const discYears = month / 12 + y - 1
  return Math.pow(1 + WACC, -discYears)
}

function getTV(d, multiple) {
  const termFactor = getTermFactor(d.WACC, multiple)
  const base = d["daFY10"] + d["oprFY10"] > 0 ? d["daFY10"] + d["oprFY10"] : d["revFY10"]
  return base * termFactor
}

function getIntrinsicEV(d, discount, multiple) {
  return seq(10, 1).reduce((cur, y) => {
    const df = getDiscFactor(discount, d.month, y)
    cur += d[`fcfFY${y}`] * df
    if (y === 10) {
      cur += getTV(d, multiple) * df
    }
    return cur
  }, 0)
}

function getIntrinsicPrice(d, discount, multiple) {
  const ev = getIntrinsicEV(d, discount, multiple)
  return (ev - zeroFallback(d.WS_DEBT_NET) + zeroFallback(d.WS_PFD_STK)) / d.Shares
}

function calcLTAvg(metric, objRes, yrs) {
  var tm = []
  yrs.forEach((y) => {
    var p = metric + y
    if (objRes.hasOwnProperty(p)) {
      tm.push(objRes[p])
    }
  })
  return average(tm)
}

export default function process(raw) {
  const d = _.cloneDeep(raw)
  for (let key of Object.keys(d)) {
    //replace null to NaN to get rid of Infinity
    if (d[key] === null) {
      d[key] = NaN
    }

    // for optional components where not report is good to treat as 0
    if ((key.startsWith("GW") && isNaN(d[key])) || d[key] === null) {
      d[key] = 0
    }

    //sometimes rev return 0.
    if (key.startsWith("revFY") && d[key] === 0) {
      d[key] = NaN
    }
  }

  ;["WACC", "eq.tcap", "Mult.EBITDA", "Mult.Sales", "P_CLOSE", "EV"].forEach((a) => {
    d[a + "Original"] = d[a]
    delete d[a] //use getter setter instead
  })

  //unify field names
  fullYears.forEach((y) => {
    d[`netNewInvFY${y}`] = d[`netNewInv${y}`]
    d[`cashFY${y}`] = d[`cash${y}`]
    d[`WCFY${y}`] = d[`WC${y}`]
    d[`PPEFY${y}`] = d[`PPE${y}`]
    d[`GWFY${y}`] = d[`GW${y}`]
    d[`intanFY${y}`] = d[`intan${y}`]
    d[`ICFY${y}`] = d[`IC{y}`]

    // unify history from WS to the same field
    if (y < 0) {
      d[`wcFY${y}`] = d[`WS_WKCAP_CHG_CF${y}Y`]
      d[`daFY${y}`] = d[`WS_DEP_EXP_CF${y}Y`]
      d[`intFY${y}`] = d[`WS_INT_EXP_NET${y}Y`]
      d[`taxFY${y}`] = d[`WS_INC_TAX${y}Y`]
      d[`capxFY${y}`] = d[`WS_CAPEX${y}Y`]
      d[`fcfFY${y}`] = d[`WS_FREE_CF${y}Y`]
    }

    //rev is the origin of a lot of factors
    d[`revFY${y}Original`] = d[`revFY${y}`]

    //history(FY<=0) need to always supply original value for field below
    d[`WCFY${y}Original`] = d[`WCFY${y}`]
    d[`PPEFY${y}Original`] = d[`PPEFY${y}`]
    d[`GWFY${y}Original`] = d[`GWFY${y}`]
    d[`intanFY${y}Original`] = d[`intanFY${y}`]

    //used to give the original value before overrides
    d[`groFY${y}Original`] = d[`revFY${y}`] / d[`revFY${y - 1}`] - 1
    d[`opmFY${y}Original`] = d[`oprFY${y}`] / d[`revFY${y}`]
    d[`capxOverRevFY${y}Original`] = d[`capxFY${y}`] / d[`revFY${y}`]
    d[`daOverRevFY${y}Original`] = d[`daFY${y}`] / d[`revFY${y}`]
    d[`wcOverRevFY${y}Original`] = d[`wcFY${y}`] / d[`revFY${y}`]
    d[`taxRateFY${y}Original`] = d[`taxFY${y}`] / (d[`oprFY${y}`] - d[`intFY${y}`])
    d[`cashFY${y}Original`] = d[`cashFY${y}`]
    d[`intanFY${y}Original`] = d[`intanFY${y}`]
  })

  applyGettersSetters(d)

  const originalsToSaveFromGetters = [
    "pctOfValueCFs",
    "pctOfValueTV",
    "currentEVOverEBITDA",
    "Mult.Term.vs.Curr",
    "LTGRev5Y",
    "LTGFcf5Y",
    "LTGCapx5Y",
    "LTGOpr5Y",
    "LTGRev10Y",
    "LTGFcf10Y",
    "LTGCapx10Y",
    "LTGOpr10Y",
    "intrinsicEV",
    "intrinsicSV",
    "updown",
    "IRR",
    "impliedPerpetualGrowth"
  ]
  originalsToSaveFromGetters.forEach((field) => {
    d[`${field}Original`] = d[field]
  })
  return d
}

function defineWritablePropertyDesc(d, f) {
  return Object.defineProperty(d, f, {
    get() {
      return d[`${f}Override`] !== undefined ? d[`${f}Override`] : d[`${f}Original`]
    },
    set(value) {
      d[`${f}Override`] = value
    }
  })
}

function defineOverrides(d) {
  //growth of rev is handled differently as history need override by value
  //every time revisit, very likely be tortured by the chick-egg thing here. :(
  const FYOverrides = ["opm", "taxRate", "wcOverRev", "capxOverRev", "daOverRev", "cash", "intan"]
  const expandedTableOverrides = CJ2(FYOverrides, fullYears).map(([f, y]) => `${f}FY${y}`)
  const allOverrides = [...expandedTableOverrides, "WACC", "eq.tcap", "Mult.EBITDA", "Mult.Sales", "P_CLOSE"]
  allOverrides.forEach((f) => defineWritablePropertyDesc(d, f))
}

export function applyGettersSetters(d) {
  defineOverrides(d)

  const Od = (field, desc) => Object.defineProperty(d, field, desc)

  //-5->10
  fullYears.forEach((y) => {
    Od(`revFY${y}`, {
      get() {
        if (y <= 0 && !isNaN(d[`revFY${y}Override`])) {
          return d[`revFY${y}Override`]
        }
        if (y > 0 && !isNaN(d[`groFY${y}`])) {
          return (1 + d[`groFY${y}`]) * d[`revFY${y - 1}`]
        } else {
          return d[`revFY${y}Original`] //need a start value here
        }
      },
      //allow override the historical absolute value
      set(value) {
        if (y > 0) throw new Error("do not override me")
        else d[`revFY${y}Override`] = value
      }
    })
    Od(`groFY${y}`, {
      get() {
        if (y <= 0) {
          return d[`revFY${y}`] / d[`revFY${y - 1}`] - 1
        } else if (!isNaN(d[`groFY${y}Override`])) {
          return d[`groFY${y}Override`]
        } else {
          return d[`groFY${y}Original`]
        }
      },
      //allow override only the estimated growth value
      set(value) {
        if (y <= 0) throw new Error("do not override me")
        else d[`groFY${y}Override`] = value
      }
    })
    Od(`absRevFY${y}`, {
      get() {
        return d[`revFY${y}`] - d[`revFY${y - 1}`]
      }
    })
    Od(`absOpFY${y}`, {
      get() {
        return d[`oprFY${y}`] - d[`oprFY${y - 1}`]
      }
    })
    Od(`incOpmFY${y}`, {
      get() {
        if (d[`absRevFY${y}`] < 0 && d[`absOpFY${y}`] < 0) {
          return -(d[`absOpFY${y}`] / d[`absRevFY${y}`])
        } else if (d[`absRevFY${y}`] > 0) {
          return d[`absOpFY${y}`] / d[`absRevFY${y}`]
        } else {
          return NaN
        }
      }
    })
    Od(`WCFY${y}`, {
      get() {
        if (y <= 0) {
          return d[`WCFY${y}Original`]
        } else {
          return d[`WCFY${y - 1}`] - d[`wcFY${y}`]
        }
      }
    })
    Od(`PPEFY${y}`, {
      get() {
        if (y <= 0) {
          return d[`PPEFY${y}Original`]
        } else {
          return d[`PPEFY${y - 1}`] + d[`capxFY${y}`] - d[`daFY${y}`]
        }
      }
    })
    Od(`GWFY${y}`, {
      get() {
        return d[`GWFY${y}Original`]
      }
    })
    //cash is overidable
    Od(`WCOverICFY${y}`, {
      get() {
        return d[`WCFY${y}`] / d[`ICFY${y}`]
      }
    })
    Od(`cashOverICFY${y}`, {
      get() {
        return d[`cashFY${y}`] / d[`ICFY${y}`]
      }
    })
    Od(`PPEOverICFY${y}`, {
      get() {
        return d[`PPEFY${y}`] / d[`ICFY${y}`]
      }
    })
    Od(`GWOverICFY${y}`, {
      get() {
        return d[`GWFY${y}`] / d[`ICFY${y}`]
      }
    })
    Od(`oprFY${y}`, {
      get() {
        return d[`revFY${y}`] * d[`opmFY${y}`]
      }
    })
    Od(`intanOverICFY${y}`, {
      get() {
        return d[`intanFY${y}`] / d[`ICFY${y}`]
      }
    })
    Od(`ICFY${y}`, {
      get() {
        return (
          zeroFallback(d[`WCFY${y}`]) +
          zeroFallback(d[`cashFY${y}`]) +
          zeroFallback(d[`PPEFY${y}`]) +
          zeroFallback(d[`GWFY${y}`]) +
          zeroFallback(d[`intanFY${y}`])
        )
      }
    })
    Od(`groICFY${y}`, {
      get() {
        return (d[`ICFY${y}`] - d[`ICFY${y - 1}`]) / Math.abs(d[`ICFY${y - 1}`])
      }
    })
    Od(`ICxGWFY${y}`, {
      get() {
        return d[`ICFY${y}`] - zeroFallback(d[`GWFY${y}`])
      }
    })
    Od(`groICxGWFY${y}`, {
      get() {
        return (d[`ICxGWFY${y}`] - d[`ICxGWFY${y - 1}`]) / Math.abs(d[`ICxGWFY${y - 1}`])
      }
    })
    Od(`groOprFY${y}`, {
      get() {
        return (d[`oprFY${y}`] - d[`oprFY${y - 1}`]) / Math.abs(d[`oprFY${y - 1}`])
      }
    })
    Od(`groCapxFY${y}`, {
      get() {
        return (d[`capxFY${y}`] - d[`capxFY${y - 1}`]) / Math.abs(d[`capxFY${y - 1}`])
      }
    })
    Od(`nopatFY${y}`, {
      get() {
        return d[`oprFY${y}`] * (1 - d[`taxRateFY${y}`])
      }
    })
    Od(`gronopatFY${y}`, {
      get() {
        return (d[`nopatFY${y}`] - d[`nopatFY${y - 1}`]) / Math.abs(d[`nopatFY${y - 1}`])
      }
    })
    Od(`nopatMgnFY${y}`, {
      get() {
        return d[`nopatFY${y}`] / d[`revFY${y}`]
      }
    })
    Od(`grofcfFY${y}`, {
      get() {
        return (d[`fcfFY${y}`] - d[`fcfFY${y - 1}`]) / Math.abs(d[`fcfFY${y - 1}`])
      }
    })
    Od(`wcFY${y}`, {
      get() {
        return d[`wcOverRevFY${y}`] * d[`revFY${y}`]
      }
    })
    Od(`capxFY${y}`, {
      get() {
        return d[`capxOverRevFY${y}`] * d[`revFY${y}`]
      }
    })
    Od(`daFY${y}`, {
      get() {
        return d[`daOverRevFY${y}`] * d[`revFY${y}`] || 0
      }
    })
    Od(`netNewInvFY${y}`, {
      get() {
        return d[`capxFY${y}`] - d[`daFY${y}`] - d[`wcFY${y}`]
      }
    })
    Od(`gronetNewInvFY${y}`, {
      get() {
        return (d[`netNewInvFY${y}`] - d[`netNewInvFY${y - 1}`]) / Math.abs(d[`netNewInvFY${y - 1}`])
      }
    })
    Od(`taxFY${y}`, {
      get() {
        return d[`taxRateFY${y}`] * (d[`oprFY${y}`] - d[`intFY${y}`])
      }
    })
    Od(`ROICFY${y}`, {
      get() {
        return (d[`nopatFY${y}`] / (d[`ICFY${y}`] + d[`ICFY${y - 1}`])) * 2
      }
    })
    Od(`ROICxGWFY${y}`, {
      get() {
        if (Number.isNaN(d[`GWFY${y}`])) return null
        return (d[`nopatFY${y}`] / (d[`ICxGWFY${y}`] + d[`ICxGWFY${y - 1}`])) * 2
      }
    })
    Od(`ROIICFY${y}`, {
      get() {
        return ((d[`nopatFY${y}`] - d[`nopatFY${y - 1}`]) * 4) / (d[`ICFY${y}`] - d[`ICFY${y - 4}`])
      }
    })
    Od(`FCFROICFY${y}`, {
      get() {
        return (d[`fcfFY${y}`] / (d[`ICFY${y}`] + d[`ICFY${y - 1}`])) * 2
      }
    })

    Od(`fcfFY${y}`, {
      get() {
        return (
          d[`daFY${y}`] +
          d[`oprFY${y}`] -
          (d[`oprFY${y}`] - d[`intFY${y}`]) * d[`taxRateFY${y}`] +
          d[`wcFY${y}`] -
          d[`capxFY${y}`]
        )
      }
    })

    Od(`EBITDAFY${y}`, {
      get() {
        return d[`daFY${y}`] + d[`oprFY${y}`]
      }
    })
    Od(`EVoverEBITDAFY${y}`, {
      get() {
        return d[`EV`] / d[`EBITDAFY${y}`]
      }
    })
    Od(`EPSFY${y}`, {
      get() {
        return d[`nopatFY${y}`] / d.Shares
      }
    })
    Od(`EVoverEPSFY${y}`, {
      get() {
        return d.EV / (d[`nopatFY${y}`] / d.Shares)
      }
    })
    Od(`OPIFY${y}`, {
      get() {
        return d[`oprFY${y}`]
      }
    })
    Od(`ROEFY${y}`, {
      get() {
        return d[`nopatFY${y}`] / (d["eq.tcap"] * d[`IC${y}`])
      }
    })
    Od(`CFROICFY${y}`, {
      get() {
        return d[`FCFROICFY${y}`]
      }
    })
    Od(`PBFY${y}`, {
      get() {
        return (d.P_CLOSE * d.Shares) / (d["eq.tcap"] * d[`IC${y}`])
      }
    })
    Od(`PSFY${y}`, {
      get() {
        return (d.P_CLOSE * d.Shares) / d[`revFY${y}`]
      }
    })
  })

  Od("effectiveExitMultipleType", {
    get() {
      return zeroFallback(d["daFY10"]) + zeroFallback(d["oprFY10"]) > 0 ? "EVEBITDA" : "Sales"
    }
  })

  Od("effectiveExitMult", {
    get() {
      return zeroFallback(d["daFY10"]) + zeroFallback(d["oprFY10"]) > 0 ? d["Mult.EBITDA"] : d["Mult.Sales"]
    }
  })

  Od("effectiveExitMultOriginal", {
    get() {
      return zeroFallback(d["daFY10"]) + zeroFallback(d["oprFY10"]) > 0
        ? d["Mult.EBITDAOriginal"]
        : d["Mult.SalesOriginal"]
    }
  })

  Od("impliedPerpetualGrowth", {
    get() {
      if (d["impliedPerpetualGrowthOverride"] !== undefined) {
        return d["impliedPerpetualGrowthOverride"]
      } else if (d["Mult.EBITDAOverride"] || d["Mult.SalesOverride"]) {
        return d.WACC - 1 / d.effectiveExitMult
      } else {
        return d.WACC - 1 / d.effectiveExitMultOriginal
      }
    }
  })

  CJ(["rev", "opr", "fcf", "capx"], [5, 10]).forEach(([item, y]) => {
    const itemCapitalized = item.charAt(0).toUpperCase() + item.slice(1)
    Od(`LTG${itemCapitalized}${y}Y`, {
      get() {
        return calcLTG(d, item, seq(y + 1))
      }
    })
  })

  let yrs10 = seq(11).slice(1)

  Od("avg_op_10y", {
    get() {
      return calcLTAvg("opmFY", d, yrs10)
    }
  })

  Od("avg_tax_10y", {
    get() {
      return calcLTAvg("taxRateFY", d, yrs10)
    }
  })

  Od("avg_wc_sales_10y", {
    get() {
      return calcLTAvg("wcOverRevFY", d, yrs10)
    }
  })

  Od("avg_capex_sales_10y", {
    get() {
      return calcLTAvg("capxOverRevFY", d, yrs10)
    }
  })

  Od("rev_gr_10y_cagr", {
    get() {
      return calcLTG(d, "rev", [0, ...yrs10])
    }
  })

  Od("currentEVOverEBITDA", {
    get() {
      return d.EV / (d.daFY0 + d.oprFY0)
    }
  })

  Od("Mult.Term.vs.Curr", {
    get() {
      return d.effectiveExitMult / d["currentEVOverEBITDA"] - 1
    }
  })

  Od("discounts", {
    get() {
      return seq(5).map((v) => d.WACC + 0.01 * (v - 2))
    }
  })

  Od("multiples", {
    get() {
      return seq(5).map((v) => d.effectiveExitMult + (v - 2))
    }
  })

  Od("updown", {
    get() {
      return getIntrinsicPrice(d, d.WACC, d.effectiveExitMult) / d.P_CLOSE - 1
    }
  })

  Od("valueCF", {
    get() {
      return seq(10, 1).reduce((acc, y) => acc + d[`fcfFY${y}`] * getDiscFactor(d.WACC, d.month, y), 0)
    }
  })

  Od("EV", {
    get() {
      return (
        d.P_CLOSE * d.Shares + zeroFallback(d.WS_DEBT_NET) + zeroFallback(d.WS_PFD_STK) + zeroFallback(d.WS_MIN_INT)
      )
    }
  })

  Od("intrinsicEV", {
    get() {
      return getIntrinsicEV(d, d.WACC, d.effectiveExitMult)
    }
  })

  Od("intrinsicSV", {
    get() {
      return d.intrinsicEV - zeroFallback(d.WS_DEBT_NET) + zeroFallback(d.WS_PFD_STK)
    }
  })

  Od("pctOfValueCFs", {
    get() {
      return d.valueCF / d.intrinsicEV
    }
  })

  Od("pctOfValueTV", {
    get() {
      return 1 - d.pctOfValueCFs
    }
  })

  Od("IRR", {
    get() {
      const allCFs = [
        -d.EV, // 0
        ...seq(9, 1).map((y) => d[`fcfFY${y}`]), // 1-9
        d[`fcfFY10`] + getTV(d, d.effectiveExitMult) //10
      ]
      return IRR(allCFs, 0.1)
    }
  })

  Od("updownScenarios", {
    get() {
      return CJ(d.discounts, d.multiples).map(([discount, multiple]) => {
        const intrinsicPrice = getIntrinsicPrice(d, discount, multiple)
        return intrinsicPrice / d.P_CLOSE - 1
      })
    }
  })

  Od("durationFCF", {
    get() {
      var stage1and2 = seq(10, 1).reduce(
        (acc, y) => acc + d[`fcfFY${y}`] * getDiscFactor(d.IRR, d.month, y) * (y - 1 + d.month / 12),
        0
      )
      var ImpliedPerpetualGrowth = (d.IRR - 1 / d.effectiveExitMultOriginal) / (1 / d.effectiveExitMultOriginal + 1)
      var Duration_Stage3 = (1 + d.IRR) / (d.IRR - ImpliedPerpetualGrowth)
      var stage3 = d["TermVal.FY10"] * getDiscFactor(d.IRR, d.month, 10) * (Duration_Stage3 + (9 + d.month / 12))
      return (stage1and2 + stage3) / (1 * d.EV)
    }
  })

  return d
}
