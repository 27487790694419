<template>
  <table class="summary-table assumption-check-table">
    <thead>
      <tr>
        <th>Assumptions Check</th>
        <th style="width:64px">Default</th>
        <th style="width:64px">Adjusted</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(m, index) in checks" :key="index">
        <td>{{ m.name }}</td>
        <td>{{ fmtNum(input[m.field + "Original"], m.digits || 0, m.percent) }}</td>
        <td>
          <TweenableNum :value="input[m.field]" :digits="m.digits" :isPercent="m.percent"></TweenableNum>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import "@/scss/site.scss"
import TweenableNum from "@/components/common/TweenableNum.vue"

export default {
  components: { TweenableNum },
  props: {
    input: { type: Object, required: true }
  },

  data() {
    return {
      checks: [
        { name: "% Value from Forecast", field: "pctOfValueCFs", percent: true },
        { name: "% Value from Terminal", field: "pctOfValueTV", percent: true },
        {
          name: "Term. Mult vs Curr.",
          field: "Mult.Term.vs.Curr",
          percent: true,
          digits: 0
        }
      ]
    }
  }
}
</script>
