var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',{staticClass:"summary-table assumption-table"},[_vm._m(0),_c('tbody',_vm._l((_vm.assumptionMetrics),function(m,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(m.name))]),_c('td',[_vm._v(_vm._s(_vm.fmtNum(m.rawValue, m.digits, m.type === "percent") + (m.type === "multiples" ? "x" : "")))]),_c('td',{staticStyle:{"width":"64px"},style:({ padding: 0, 'border-bottom': m.override ? '1px solid #999' : 'none' })},[(m.override)?_c('OverrideInput',_vm._b({on:{"updateData":function($event){return _vm.$emit('update', $event)}}},'OverrideInput',{
            input: _vm.input,
            showInit: false,
            field: m.field,
            digits: m.digits,
            type: m.type,
            min: m.min,
            max: m.max,
            textAlign: 'center'
          },false)):_vm._e(),_c('div',{staticStyle:{"color":"blue","font-weight":"bold"}},[(m.field === 'impliedPerpetualGrowth' && _vm.input.impliedPerpetualGrowth != m.rawValue)?_c('TweenableNum',{attrs:{"value":_vm.input.impliedPerpetualGrowth,"digits":m.digits,"isPercent":true}}):_vm._e()],1)],1)])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("DCF Inputs")]),_c('th',{staticStyle:{"width":"64px"}},[_vm._v("Default")]),_c('th',{staticStyle:{"width":"64px"}},[_vm._v("Override")])])])}]

export { render, staticRenderFns }