<template>
  <v-btn text small @click="feedbackSend">
    <v-icon class="mr-2">mdi-comment-quote-outline</v-icon>
  </v-btn>
</template>

<script>
export default {
  methods: {
    feedbackSend() {
      const mailto = "dgrogan@williamblair.com; pwang@williamblair.com; awickman@williamblair.com"
      const cc = "gscolaro@williamblair.com"
      const subject = "Feedback for WB DCF tool"
      const body = `URL: ${window.location.href}`
      location.href = `mailto:${mailto}?cc=${cc}&subject=${subject}&body=${body}`
    }
  }
}
</script>
