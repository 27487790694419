<template>
  <div class="d-flex dcf-toolbar align-center py-1">
    <v-btn text small :disabled="!canUndo" @click="undo"><v-icon>mdi-undo</v-icon></v-btn>
    <v-btn text small :disabled="!canRedo" @click="redo"><v-icon>mdi-redo</v-icon></v-btn>
    <v-btn text small @click="reload">
      <v-icon>mdi-refresh</v-icon>
      Reload
    </v-btn>
    <v-btn
      text
      small
      @click="startSave"
      style="transition: margin-left 0.2s ease-in 0.3s"
      :style="{ 'margin-left': showSaveTitleInput ? '-138px' : 0 }"
      :disabled="!override"
      v-if="!showSaveTitleInput"
    >
      <v-icon>mdi-content-save</v-icon>
      Save As
    </v-btn>

    <v-btn
      text
      small
      @click="save(true)"
      style="transition: margin-left 0.2s ease-in 0.3s"
      :style="{ 'margin-left': showSaveTitleInput ? '-138px' : 0 }"
      :disabled="currentVersion === '' || !userCanEditDelete"
      v-if="!showSaveTitleInput"
    >
      <v-icon>mdi-content-save-edit</v-icon>
      Update
    </v-btn>

    <div
      v-if="showUpdateConfirmation"
      class="caption green--text accent-3 mx-2 d-flex align-center"
      style="height: 28px; white-space: nowrap"
    >
      <v-icon color="green">mdi-check</v-icon>
      Override update saved successfully.
    </div>

    <div
      v-if="showSaveTitleInput"
      class="mr-2"
      style="height: 28px;  width: 210px; overflow: hidden; transition: opacity 1s cubic-bezier(0.4, 1, 0.4, 1)"
      :style="{
        opacity: showSaveTitleInput || showAcceptConfirmation ? 1 : 0
      }"
    >
      <div
        :style="{
          position: 'relative',
          padding: '2px 0',
          transition: 'top 0.3s ease-out',
          top: showAcceptConfirmation && titleInvalid ? '-56px' : showAcceptConfirmation ? '-28px' : '2px'
        }"
      >
        <div class="d-flex px-2">
          <v-text-field
            ref="titleEl"
            v-model="savedVersion"
            dense
            outlined
            hide-details
            autofocus
            class="custom-title-input"
            placeholder="Enter title to save"
            @keydown.enter="acceptTitle"
            :rules="[versionNameRule]"
            autocomplete="off"
          ></v-text-field>
          <v-btn icon small color="primary" class="px-0" @click="acceptTitle">
            <v-icon small>mdi-check</v-icon>
          </v-btn>
          <v-btn icon small color="error" class="px-0" @click="resetSave">
            <v-icon small>mdi-close</v-icon>
          </v-btn>
        </div>
        <div
          v-if="showAcceptConfirmation"
          class="caption green--text accent-3 mx-2 my-1 d-flex align-center"
          style="height: 28px; white-space: nowrap"
        >
          <v-icon color="green">mdi-check</v-icon>
          Override saved successfully.
        </div>
        <div
          v-if="showAcceptConfirmation && titleInvalid"
          class="caption: red--text accent-3 mx-2 my-1 d-flex align-center"
          style="height: 28px; white-space: nowrap"
        >
          <v-icon color="orange">mdi-info</v-icon>
          The given name is invalid.
        </div>
      </div>
    </div>

    <v-spacer></v-spacer>
    <select
      class="custom-select mr-2"
      style="width:184px"
      :value="currentVersion"
      @change="switchVersion($event.target.value)"
    >
      <option value="">
        <span>Select from {{ versionList.length }} Override</span>
        <span v-if="versionList.length > 0">s</span>
      </option>
      <option
        v-for="({ name, lastModified, analyst, override_id }, index) in versionList"
        :value="override_id"
        :key="index"
      >
        [{{ formatDate(lastModified) }}] - {{ name }} - {{ analyst }}
      </option>
    </select>

    <v-btn
      text
      small
      :disabled="!currentVersion || versionList.length === 0 || !userCanEditDelete"
      @click="deleteVersion"
    >
      <v-icon class="mr-2">mdi-delete</v-icon>
    </v-btn>

    <SimpleFeedbackButton />

    <v-btn text small @click="printWindow">
      <v-icon class="mr-2">mdi-printer</v-icon>
    </v-btn>
    <v-btn text small target="_blank" :href="`${publicPath}files/WACC DCF Model Methodology.pdf`">
      <v-icon>mdi-download</v-icon>
      Methodology
    </v-btn>
  </div>
</template>

<script>
import equal from "fast-deep-equal"
import format from "date-fns/format"
import SimpleFeedbackButton from "../common/SimpleFeedbackButton.vue"
import { saveOverride, saveDerived, loadVersions, deleteOverride, processOverride } from "@/apis/override.js"
import _ from "lodash"
import { mapGetters } from "vuex"

export default {
  components: { SimpleFeedbackButton },
  props: {
    id: { type: String, required: true },
    input: { type: Object, required: true }
  },

  data() {
    return {
      undoHistory: [],
      redoQueue: [],
      savedVersion: "",
      currentVersion: "",
      versionLastModified: "",
      publicPath: process.env.BASE_URL,
      versionList: [],
      showSaveTitleInput: false,
      showAcceptConfirmation: false,
      showUpdateConfirmation: false,
      overrideDescFields: [
        "name",
        "version",
        "override_id",
        "scenario_name",
        "security_id",
        "day",
        "analyst",
        "FYASnapshot",
        "issuer_id",
        "note"
      ]
    }
  },

  mounted() {
    this.loadVersions()
  },

  computed: {
    ...mapGetters("dcfStore", ["selectedScenario", "userCanAuthor"]),
    titleInvalid() {
      return !this.versionNameRule(this.savedVersion)
    },
    userCanEditDelete() {
      var userIsAuthor = false
      if (this.override) {
        userIsAuthor = this.override.analyst === this.$root.user.name
      }
      return this.userCanAuthor || userIsAuthor
    },
    override() {
      const fields = Object.keys(this.input)
      const overrideFields = fields
        .filter((d) => /.*Override$/.test(d))
        .filter((d) => {
          const d2 = d.replace("Override", "")
          const v = this.input[`${d2}`] //always has te latest value
          const ov = this.input[`${d2}Original`]
          return v !== undefined && !equal(v, ov)
        })
      if (overrideFields.length === 0) {
        return null
      } else {
        const changeEntries = Object.entries(this.input).filter(([k]) => overrideFields.includes(k))
        const changes = Object.fromEntries(changeEntries)

        const his = this.undoHistory
        if (his.length === 0 || !equal(his[his.length - 1], changes)) {
          his.push(changes)
        }

        //shallow clone to prevent fields below from being added to history
        const result = { ...changes }
        if (this.input.note !== null) {
          result.note = this.input.note
        }

        //always save the time being FYA for future alignment
        result.FYASnapshot = this.$root.secInfo.FYA

        //copy the description fields if they are available
        this.overrideDescFields.forEach((f) => {
          if (_.has(this.input, f) && !_.has(result, f)) {
            result[f] = this.input[f]
          }
        })

        return result
      }
    },
    canUndo() {
      return this.undoHistory.length > 0
    },
    canRedo() {
      return this.redoQueue.length > 0
    }
  },

  methods: {
    formatDate(dStr) {
      return format(new Date(dStr), "MM/dd/yyyy")
    },
    undo() {
      if (this.undoHistory.length === 0) return

      //undo: [1,2,3] -> [1,2]
      //redo: [] -> [3]
      const cur = this.undoHistory.pop()
      this.redoQueue.unshift(cur)

      //2
      const prev = this.undoHistory[this.undoHistory.length - 1]

      // this will trigger current change be pu t in the undoHistory.
      // always need to pop twice to get the previous update
      this.$emit("update", { reload: true, readonly: true, ...prev })
      //undo: [1,2]
    },
    redo() {
      if (this.redoQueue[0]) {
        this.$emit("update", { reload: true, readonly: true, ...this.redoQueue.shift() })
      }
    },
    versionNameRule(value) {
      return value.length < 200
    },
    async loadVersions() {
      this.versionList = await loadVersions(this.QUANT_API_HOST, this.id, this.$root.user.name)
    },
    genTitle() {
      const date = new Date()
      const month = date.getMonth() + 1
      const day = date.getDate()
      return `version-${String(month).padStart(2, "0")}-${String(day).padStart(2, "0")}`
    },
    startSave() {
      // this.savedVersion = this.genTitle()
      this.showSaveTitleInput = true
      this.showAcceptConfirmation = false
    },
    async save(update = false, title = null) {
      this.override.analyst = this.user.name
      this.override.day = new Date().toISOString().split("T")[0]

      if (!update) {
        if (title) {
          this.override.name = title
          this.override.version = title
        }
        this.override.override_id = null
      }

      const { data: savedOVerride, status } = await saveOverride(this.QUANT_API_HOST, this.override)

      if (update && status == 200) {
        this.showUpdateConfirmation = true
        setTimeout(() => (this.showUpdateConfirmation = false), 3000)
      }

      await this.loadVersions()
      this.currentVersion = savedOVerride.override_id
      this.override.override_id = savedOVerride.override_id
      this.input.override_id = savedOVerride.override_id
      await saveDerived(this.QUANT_API_HOST, this.input)
    },
    resetSave() {
      this.showSaveTitleInput = false
      this.showAcceptConfirmation = false
    },
    async acceptTitle() {
      this.showAcceptConfirmation = true
      setTimeout(() => {
        this.showAcceptConfirmation = false
        this.showSaveTitleInput = false
      }, 2500)

      if (!this.versionNameRule(this.savedVersion)) {
        return
      }
      await this.save(false, this.savedVersion)
    },
    switchVersion(v) {
      this.undoHistory = []
      this.redoQueue = []
      this.currentVersion = Number(v)
    },
    async deleteVersion() {
      if (this.currentVersion) {
        this.currentVersion
        let v = _.find(this.versionList, { override_id: this.currentVersion })
        if (v && v.override_id) {
          await deleteOverride(this.QUANT_API_HOST, v.override_id)
          this.versionList = this.versionList.filter((v) => {
            return v.override_id != this.currentVersion
          })
        }
      }

      await this.loadVersions()
      this.currentVersion = this.versionList[this.versionList.length - 1].override_id
    },
    printWindow() {
      window.print()
    },
    reload() {
      //this also create a reload via watch when currentVersion change to empty string from another value
      //so the $emit code will run twice, but it's not a big deal here
      this.currentVersion = ""
      this.$emit("update", { reload: true })
    }
  },
  watch: {
    async currentVersion(v) {
      if (v === null || v === undefined || v === "") {
        this.$emit("update", { reload: true })
      } else {
        const update = _.find(this.versionList, { override_id: v })
        const FYACurrent = this.$root.secInfo.FYA

        var overrides = processOverride(update, FYACurrent)

        this.$emit("update", { reload: true, readonly: true, ...overrides })
      }
    },
    selectedScenario(s) {
      let v = _.find(this.versionList, { override_id: s.override_id })
      if (v) {
        this.currentVersion = s.override_id
      }
    }
  }
}
</script>

<style lang="scss">
.dcf-toolbar {
  border-top: 1px solid #014165;
  border-bottom: 1px solid #014165;
  background-color: #d1d1d1;
  color: #333;

  .v-btn {
    text-transform: none;
    color: #333;
  }

  .custom-select {
    appearance: listbox;
    background: #fff;
    border: 1px solid #999;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 500;
    line-height: 28px;
    height: 28px;
    padding: 0 8px;
    border-radius: 4px;
  }

  .custom-pager .v-btn.v-btn--custom {
    min-width: 28px;
  }

  .animate-element {
    will-change: margin-top;
    height: 28px;
    transform: margin-top 1s;
  }

  .show-confirmation {
    margin-top: -28px;
  }
}
</style>
