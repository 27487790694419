<template>
  <div v-if="secInfo">
    <RIMTool v-if="showRIM" :id="id"></RIMTool>
    <DCFTool v-else :id="id"></DCFTool>
  </div>
</template>
<script>
import DCFTool from "@/views/DCFTool.vue"
import RIMTool from "@/views/RIMTool.vue"
import { mapActions } from "vuex"
import _ from "lodash"

export default {
  components: {
    DCFTool,
    RIMTool
  },
  props: {
    id: { type: String, required: true }
  },
  data() {
    return {
      secInfo: null
    }
  },
  async mounted() {
    const securityApiClient = await this.makeAPIClient(this.QUANT_API_HOST)

    const validAuthors = await securityApiClient.get(`valuation/GetDcfValidAuthors?security_id=${this.id}`)
    var validUser = _.find(validAuthors.data, { last_name_first: this.$root.user.name })
    if (validUser) {
      this.setUserCanAuthor(true)
    }

    const info = await securityApiClient.get(`valuation/GetDcfSecurityInfo?security_id=${this.id}`)
    this.secInfo = info.data
  },

  computed: {
    showRIM() {
      return (
        this.secInfo &&
        (this.secInfo.INDGRP === "Banks" || this.secInfo.IND === "Diversified Financial Services") &&
        this.$route.query.model !== "DCF"
      )
    }
  },
  methods: {
    ...mapActions("dcfStore", ["setUserCanAuthor"])
  }
}
</script>
