<template>
  <table class="custom-table" v-if="input">
    <thead>
      <tr>
        <th style="width: 200px"></th>
        <th v-for="(fy, order) in fys" :key="order" :class="{ forecast: order > 5 }" v-html="`FY${fy + curFY}`"></th>
      </tr>
    </thead>
    <template v-for="(row, index) in rowItems">
      <tr v-if="row.override" :class="{ 'override-row': true }" :key="`override_${index}`">
        <td
          :class="{ indented: row.child, percent: row.percent && row.name !== 'opm' }"
          v-html="`<div>${row.label}</div>`"
        ></td>
        <td
          v-for="(fy, order) in fys"
          :key="order"
          :class="{
            percent: row.percent,
            forecast: order > 5
          }"
        >
          <div v-if="row.name.startsWith('gro') && isNaN(input[`${row.name}FY${fy}`])"></div>
          <TweenableNum
            :style="{ marginRight: row.percent ? '14px' : 'auto' }"
            v-else-if="(row.name === 'rev' && fy >= 0) || (row.name === 'gro' && fy <= 0)"
            :value="input[`${row.name}FY${fy}`]"
            :digits="row.digits"
            :isPercent="row.percent"
          ></TweenableNum>
          <OverrideInput
            v-else
            v-bind="{
              input,
              field: `${row.name}FY${fy}`,
              type: row.percent ? 'percent' : 'value',
              digits: row.digits,
              showInit: true
            }"
            @updateData="$emit('updateOverride', $event)"
          />
        </td>
      </tr>
      <tr v-else :key="index" :class="{ [`row-${row.name}`]: true }">
        <td :class="{ indented: row.child, percent: row.percent }">
          <div>
            <div class="d-inline-block">
              {{ row.label }}
            </div>
          </div>
        </td>
        <td v-for="(fy, order) in fys" :key="order" :class="{ percent: row.percent }">
          <TweenableNum
            :value="input[`${row.name}FY${fy}${row.override ? 'Original' : ''}`]"
            :digits="row.digits"
            :isPercent="row.percent"
          ></TweenableNum>
        </td>
      </tr>
    </template>
  </table>
</template>

<script>
import OverrideInput from "@/components/common/OverrideInput.vue"
import TweenableNum from "@/components/common/TweenableNum.vue"
export default {
  components: { OverrideInput, TweenableNum },
  props: {
    input: { type: Object, required: true }
  },
  data() {
    return {
      fys: Array(11)
        .fill(0)
        .map((_, i) => i),
      rowItems: [
        { label: "Total Revenue", name: "rev", override: true },
        { label: "YoY", name: "grorev", child: true, percent: true, override: true },
        { label: "Operating Expense", name: "opExp", child: true },
        { label: "Cost to Income", name: "costToInc", child: true, percent: true, override: true },
        { label: "PPOP", name: "opr" },
        { label: "Total Provisioning", name: "llp", child: true },
        { label: "YoY", name: "grollp", child: true, percent: true, override: true },
        { label: "Profit Before Tax", name: "profitBeforeTax" },
        { label: "Income Tax", name: "tax", child: true },
        { label: "Tax Rate", name: "taxRate", child: true, percent: true, override: true },
        { label: "Net Profit", name: "ni" },
        { label: "YoY", name: "groni", child: true, percent: true },
        { label: "Total Shareholders' Equity", name: "BV" },
        { label: "YoY", name: "groBV", child: true, percent: true, override: true },
        { label: "ROE", name: "roe", percent: true },
        { label: "EPS", name: "eps", digits: 2 },
        { label: "Residual EPS", name: "reps", digits: 2 },
        { label: "BVPS", name: "bvps" },
        { label: "Net CF", name: "netCF" }
      ]
    }
  },
  computed: {
    curFY() {
      if (this.$root.secInfo?.FYA) {
        return parseInt(this.$root.secInfo.FYA.split("-")[0]) - 2000
      }
      return 0
    }
  },
  mounted() {
    window.__input__ = this.input
  }
}
</script>

<style lang="scss">
.custom-table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid #999;
  border-collapse: collapse;

  th {
    height: 2em;
    text-align: right;
    padding: 2px 4px;
    border-top: 1px solid #616161;
    border-bottom: 1px solid #616161;
    background: #d9d9d9;
  }

  thead:first-child th {
    height: 3em;
  }

  thead th.col-group {
    font-family: "Roboto Slab", serif;
    font-weight: 600;
    text-align: center;
    border-right: 1px solid #000;
    background: #595959;
    color: #fff;
    font-style: italic;
  }

  tr.table-section-header td {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background-color: #808080;
    color: #fff;
    font-size: 13px;
    padding: 0 4px;
  }

  td {
    text-align: right;
    color: #000;
  }

  td:first-child {
    background: #f2f2f2;
    font-weight: 400;
    text-align: left;
    border-right: 1px solid #000;
    font-family: "Roboto Slab", serif;
  }

  th:nth-child(7),
  th:nth-child(12),
  td:nth-child(7),
  td:nth-child(12) {
    /*enforce important for printer */
    border-right: 1px solid #000;
  }

  td.percent {
    color: #4d4f53;
  }

  td.indented {
    color: #666;
    padding-left: 24px !important;
  }

  td.forecast {
    background: #eee;
  }

  tr td > div {
    max-height: 0;
    box-sizing: border-box;
    overflow: hidden;
    height: 24px;
    padding: 0px 6px;
    transition: max-height 0.3s;
  }

  tr.row-fcf td,
  tr.row-IC td,
  tr.row-capx td {
    border-top: 2px double #3c3c3c;
  }
  td.row-opm td {
    font-style: normal !important;
  }
}
</style>
