var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.input)?_c('table',{staticClass:"custom-table"},[(_vm.revisionRequired)?_c('caption',{staticStyle:{"font-size":"12px","text-align":"left","padding-left":"200px"}},[(!_vm.input.fyAdjustOnly)?_c('div',{staticStyle:{"color":"#c25339"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-alert-circle-outline")]),_vm._v(" Hist #s may not align with FY period, click "),_c('a',{staticClass:"pa-0",staticStyle:{"text-shadow":""},attrs:{"small":"","text":""},on:{"click":_vm.reviseFYRev}},[_vm._v("HERE")]),_vm._v(" to shift FY then input missing FYA value. ")],1):_c('div',{staticStyle:{"color":"green"}},[_c('v-icon',{attrs:{"small":"","color":"green"}},[_vm._v("mdi-check")]),_vm._v(" Historical revenue numbers (FY-1 to FY-5) successfully shifted. "),_c('span',{staticStyle:{"padding":"0 4px","background":"#d7d787"}},[_vm._v(" FY0 still requires manual override.")])],1)]):_vm._e(),_vm._m(0),_c('thead',[_c('tr',[_c('th'),_vm._l((_vm.fys),function(fy,order){return _c('th',{key:order,class:{ forecast: order > 5 },domProps:{"innerHTML":_vm._s(("FY" + (fy + _vm.curFY)))}})})],2)]),_vm._l((_vm.rowItems),function(row,index){
var _obj;
return [(row.type === 'table-section-header')?_c('tr',{key:index,staticClass:"table-section-header"},[_c('td',{attrs:{"colspan":"17"}},[_c('div',[_vm._v(_vm._s(row.label))])])]):[(row.override)?_c('tr',{key:("override_" + index),class:{ 'override-row': true, 'row-folded': _vm.foldMe(index) }},[(row.foldAbove)?_c('td',[_c('div',[_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(row.label)+" "),(row.foldAbove)?_c('v-icon',{staticClass:"d-print-none",on:{"click":function($event){return _vm.$set(row, 'folded', !row.folded)}}},[_vm._v(" mdi-"+_vm._s(row.folded ? "plus" : "minus")+"-box-outline ")]):_vm._e()],1)])]):_c('td',{class:{ indented: row.child, percent: row.percent && row.name !== 'opm' },domProps:{"innerHTML":_vm._s(("<div>" + (row.label) + "</div>"))}}),_vm._l((_vm.fys),function(fy,order){return _c('td',{key:order,class:{
            percent: row.percent,
            forecast: order > 5,
            'revision-required': row.name === 'rev' && _vm.revisionRequired && fy <= 0
          }},[(row.name.startsWith('gro') && isNaN(_vm.input[((row.name) + "FY" + fy)]))?_c('div'):((row.name === 'rev' && fy > 0) || (row.name === 'gro' && fy <= 0))?_c('TweenableNum',{attrs:{"value":_vm.input[((row.name) + "FY" + fy)],"digits":row.digits,"isPercent":row.percent}}):_c('OverrideInput',_vm._b({on:{"updateData":function($event){return _vm.$emit('updateOverride', $event)}}},'OverrideInput',{
              input: _vm.input,
              field: ((row.name) + "FY" + fy),
              type: row.percent ? 'percent' : 'value',
              digits: row.digits,
              showInit: true
            },false))],1)})],2):_c('tr',{key:index,class:( _obj = {}, _obj[("row-" + (row.name))] = true, _obj['row-folded'] =  _vm.foldMe(index), _obj )},[_c('td',{class:{
            indented: row.child,
            percent: row.percent && row.name !== 'opm'
          }},[_c('div',[_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(row.label)+" "),(row.foldAbove)?_c('v-icon',{staticClass:"d-print-none",on:{"click":function($event){return _vm.$set(row, 'folded', !row.folded)}}},[_vm._v(" mdi-"+_vm._s(row.folded ? "plus" : "minus")+"-box-outline ")]):_vm._e()],1)])]),_vm._l((_vm.fys),function(fy,order){return _c('td',{key:order,class:{ forecast: order > 5, percent: row.percent }},[_c('TweenableNum',{attrs:{"value":_vm.input[((row.name) + "FY" + fy + (row.override ? 'Original' : ''))],"digits":row.digits,"isPercent":row.percent}})],1)})],2)]]})],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"196px","border-right":"1px solid #000","background-color":"#91969c"}}),_c('th',{staticClass:"col-group",staticStyle:{"background-color":"#91969c"},attrs:{"colspan":"6"}},[_vm._v("Historical")]),_c('th',{staticClass:"col-group",attrs:{"colspan":"5"}},[_vm._v("Stage 1 Growth")]),_c('th',{staticClass:"col-group",attrs:{"colspan":"5"}},[_vm._v("Stage 2 Growth")])])])}]

export { render, staticRenderFns }