<template>
  <div class="dcf-tool-wrapper" v-if="input" @keyup.ctrl="shortCutPress">
    <div class="px-4">
      viewing RIM DCF currently. you may also
      <router-link :to="alternativeModelURL">view WACC DCF here</router-link>
    </div>

    <Toolbar ref="rimToolbar" :input="input" :id="id" class="d-print-none" @update="updateData" />
    <div class="d-flex py-2">
      <div class="px-1" style="width: 23%">
        <Assumptions :input="input" @update="updateData" />
      </div>
      <div class="px-1" style="width: 23%">
        <AssumptionChecks :input="input" />
        <LongTermGrowth :input="input" class="mt-2" />
      </div>
      <div class="px-1" style="width: 27%">
        <ModelOutputs :input="input" />
        <!-- <OverrideNote class="mt-2" :content="input.note" @updateNote="updateData({ reload: false, note: $event })" /> -->
      </div>
      <div class="pl-1" style="width: 27%">
        <UpsideDownsideAnalysis :input="input" />
      </div>
    </div>
    <RIMDataTable :input="input" @updateOverride="updateData" />
    <div class="d-print-none">
      <v-switch
        v-model="showFormulas"
        @click="setMathjax"
        :label="`${showFormulas ? 'Hide' : 'Show'} Formulas`"
      ></v-switch>
      <div v-if="showFormulas" class="d-flex space-between" v-text="rimFormulas"></div>
    </div>
  </div>
</template>

<script>
import qs from "qs"
import preprocess, { applyGettersSetters } from "../utils/rim_data_preprocessor.js"
import Toolbar from "@/components/RIMTool/RIMActionToolbar.vue"
import Assumptions from "@/components/RIMTool/Assumptions.vue"
import AssumptionChecks from "@/components/RIMTool/AssumptionChecks.vue"
import LongTermGrowth from "@/components/RIMTool/LongTermGrowth.vue"
import ModelOutputs from "@/components/RIMTool/ModelOutputs.vue"
import UpsideDownsideAnalysis from "@/components/RIMTool/UpsideDownsideAnalysis.vue"
import RIMDataTable from "@/components/RIMTool/RIMDataTable.vue"
import EventBus from "@/EventBus.js"
import rimFormulas from "!raw-loader!@/misc/rim-formulas.tex"

export default {
  components: {
    Toolbar,
    Assumptions,
    AssumptionChecks,
    LongTermGrowth,
    ModelOutputs,
    UpsideDownsideAnalysis,
    RIMDataTable
  },
  props: {
    id: { type: String, required: true }
  },
  data() {
    return {
      rimFormulas,
      showFormulas: false,
      input: {}
    }
  },
  async mounted() {
    try {
      const quantApiClient = await this.makeAPIClient(this.QUANT_API_HOST)

      const [ivDataResponse, ivBankdataResponse] = await Promise.all([
        quantApiClient.get(`/valuation/GetIvData?security_id=${this.id}`),
        quantApiClient.get(`/valuation/GetIvBankData?security_id=${this.id}`)
      ])
      const ivData = { ...ivDataResponse.data[0], ...ivBankdataResponse.data[0] }

      this.rawInput = ivData
      this.input = preprocess(ivData)
    } catch (e) {
      console.error("failed to fetch valuation data")
      console.error(e)
      return
    }

    EventBus.$on("updateData", this.updateData.bind(this))

    this.updateData({ reload: true, ...{} })

    // delete hash
    history.pushState("", document.title, location.pathname + location.search)
  },
  computed: {
    alternativeModelURL() {
      const query = qs.parse(this.$route.query)
      return location.pathname + "?" + qs.stringify({ ...query, model: "DCF" })
    }
  },
  methods: {
    setMathjax() {
      if (this.showFormulas) {
        this.$nextTick(() => {
          MathJax.typeset()
        })
      }
    },
    updateData($event) {
      if ($event.reload) {
        this.input = preprocess(this.rawInput)
      }
      Object.entries($event).forEach(([k, v]) => {
        this.$set(this.input, k, v)
      })
      //custom getter will not trigger vue rerender
      this.input = applyGettersSetters({ ...this.input })
    }
  }
}
</script>
