var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dcf-summary"},[_c('v-sheet',{attrs:{"elevation":"1","rounded":"","width":"440"}},[(_vm.scenarios)?_c('table',{staticClass:"summary-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v(" Scenarios "),(_vm.userCanAuthor)?_c('span',{staticClass:"edit-btn",on:{"click":function($event){_vm.showDcfSummaryDialog = true}}},[_vm._v("edit")]):_vm._e()]),_vm._l((_vm.scenarios),function(s){return _c('th',{key:s.override_id,staticClass:"scenario-link",on:{"click":function($event){return _vm.scenarioSelected(s)}}},[_vm._v(" "+_vm._s(s.scenario_name)+" ")])}),_c('th',[_vm._v("Wgt Avg")])],2)]),_c('tbody',[_c('tr',[_c('td',[_vm._v("Probability")]),_vm._l((_vm.scenarios),function(s){return _c('td',{key:s.override_id},[_c('TweenableNum',{attrs:{"value":s.probability,"digits":0,"isPercent":true}})],1)}),_c('td')],2),_vm._l((_vm.measures),function(m){return _c('tr',{key:m.name},[_c('td',[_vm._v(_vm._s(m.name))]),_vm._l((_vm.scenarios),function(s){return _c('td',{key:s.override_id},[_c('TweenableNum',{attrs:{"value":s[m.field],"digits":m.digits,"isPercent":m.isPercent}})],1)}),_c('td',{staticClass:"numeric-val",style:({
              background: _vm.wgtAvgs[m.field] ? _vm.colorize(_vm.wgtAvgs[m.field], [0, 0.1]) : '#fff'
            })},[_c('TweenableNum',{attrs:{"value":_vm.wgtAvgs[m.field],"digits":m.digits,"isPercent":m.isPercent}})],1)],2)})],2)]):_vm._e()]),_c('v-dialog',{attrs:{"width":"600"},model:{value:(_vm.showDcfSummaryDialog),callback:function ($$v) {_vm.showDcfSummaryDialog=$$v},expression:"showDcfSummaryDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Update DCF Scenarios")]),_c('v-card-text',[_c('table',{staticClass:"summmary-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("Scenario")]),_c('th',[_vm._v("Overrides")]),_c('th',[_vm._v("Probability")])])]),_vm._l((_vm.formData),function(s){return _c('tr',{key:s.override_id},[_c('td',[_vm._v(_vm._s(s.scenario_name))]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(s.override_id),expression:"s.override_id"}],staticClass:"custom-select mr-2",staticStyle:{"width":"400px"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(s, "override_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_c('span',[_vm._v("Select from "+_vm._s(_vm.versionList.length)+" Override")]),(_vm.versionList.length > 0)?_c('span',[_vm._v("s")]):_vm._e()]),_vm._l((_vm.versionList),function(ref,index){
            var name = ref.name;
            var lastModified = ref.lastModified;
            var analyst = ref.analyst;
            var override_id = ref.override_id;
return _c('option',{key:index,domProps:{"value":override_id}},[_vm._v(" ["+_vm._s(_vm.formatDate(lastModified))+"] - "+_vm._s(name)+" - "+_vm._s(analyst)+" ")])})],2)]),_c('td',[_c('div',{staticClass:"probability-input"},[_c('v-text-field',{attrs:{"type":"number","suffix":"%"},model:{value:(s.probability),callback:function ($$v) {_vm.$set(s, "probability", $$v)},expression:"s.probability"}})],1)])])})],2),_c('div',{staticClass:"errors",staticStyle:{"height":"80px"}},[_c('ul',_vm._l((_vm.errors),function(e){return _c('li',{key:e},[_c('span',{staticClass:"validation-error"},[_vm._v(_vm._s(e))])])}),0)])]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"secondary"},on:{"click":function($event){_vm.showDcfSummaryDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }