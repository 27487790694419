<template>
  <div class="override-note" @click="setActive">
    <div v-show="content || isEditing" class="editor-wrap" :class="{ editing: isEditing, readonly: !isEditing }">
      <div class="editor-readonly ql-container" @click="isEditing = !prevContent">
        <div class="ql-editor" ref="editorReadOnly"></div>
      </div>
      <div class="editor" ref="editor"></div>
    </div>
    <div style="position: absolute; bottom:0; right:0; z-index: 2">
      <v-btn style="text-transform:none" v-if="!isEditing" x-small text color="primary" @click="setActive">
        Click to add or edit override commentary
      </v-btn>
    </div>
  </div>
</template>

<script>
import Quill from "quill"
import equal from "fast-deep-equal"

const Delta = Quill.import("delta")
const BackgroundClass = Quill.import("attributors/class/background")
const ColorClass = Quill.import("attributors/class/color")
Quill.register(BackgroundClass, true)
Quill.register(ColorClass, true)
const color = ["black", "red", "orange", "yellow", "green", "blue", "purple"]
const background = ["white", "red", "orange", "yellow", "green", "blue", "purple"]
const quillConfig = {
  modules: {
    toolbar: [
      ["bold", "italic", "underline", { align: ["", "center", "right", "justify"] }],
      [{ background }, { color }, { indent: "-1" }, { indent: "+1" }, { list: "ordered" }, { list: "bullet" }]
    ]
  },
  theme: "snow"
}

export default {
  props: {
    content: {
      type: Object,
      default() {
        return { ops: [{ insert: "" }] }
      }
    }
  },
  data() {
    return {
      quill: null,
      isEditing: false,
      snack: false,
      snackColor: "",
      snackText: "",
      prevContent: null
    }
  },
  mounted() {
    const vm = this
    const quill = new Quill(this.$refs.editor, quillConfig)
    this.$options.quill = quill

    let change = new Delta()
    quill.on("text-change", function(delta) {
      change = change.compose(delta)
    })

    setInterval(function() {
      if (change.length() > 0) {
        const newContent = quill.getContents()
        if (equal(newContent, vm.content)) return

        vm.$emit("updateNote", newContent)
        vm.$options.selection = quill.getSelection()

        change = new Delta()
      }
    }, 1000)

    quill.setContents(this.content.ops)
    this.$nextTick(() => (this.$refs.editorReadOnly.innerHTML = quill.root.innerHTML))
  },
  updated() {
    const quill = this.$options.quill
    quill.setContents(this.content.ops)
    quill.setSelection(this.$options.selection)
    this.$nextTick(() => (this.$refs.editorReadOnly.innerHTML = this.$options.quill.root.innerHTML))
  },
  methods: {
    setActive() {
      this.isEditing = true
      this.$options.quill.focus()
    }
  }
}
</script>

<style lang="scss" scoped>
.override-note {
  border: 1px solid #999;
  position: relative;
}

.editor-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 80px;
}

::v-deep .editor-wrap.readonly {
  /* .ql-toolbar is generated by quill library, wont't be attached by attrbute selector*/
  .ql-toolbar {
    display: none;
  }
  .editor {
    display: none;
  }
  .editor-readonly {
    display: block;
  }
}

::v-deep .editor-wrap.editing {
  padding-bottom: 32px; /* reserve for toolbar */
  background: #fffdd9;
  .ql-toolbar {
    display: block;
  }
  .editor {
    display: block;
  }
  .editor-readonly {
    display: none;
  }
}

::v-deep .v-snack .v-snack__wrapper {
  min-height: 32px;
  max-height: 32px;
  min-width: 160px;
  max-width: 360px;
  margin: 0;
}

.editor-wrap.editing .ql-container {
  max-height: 400px;
  overflow-y: auto;
  border: none;
}

::v-deep .ql-editor {
  border: none;
  padding: 2px 4px;
}

::v-deep .ql-editor {
  img {
    max-width: 100%;
  }
  p {
    margin-bottom: 0.25em;
  }
  ol,
  ul {
    padding-left: 0.5em;
  }
}

::v-deep .ql-toolbar.ql-snow {
  padding: 0 !important;
  position: absolute;
  bottom: 24px;
  width: 100%;
  transform: translateY(100%);
  border: none;
}

.editor-wrap__content-card {
  box-sizing: border-box;

  position: absolute;
  top: 32px;
  left: 16px;
  bottom: 16px;
  right: 16px;
  overflow: auto;
}
</style>
