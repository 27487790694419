<template>
  <table class="summary-table model-input-table">
    <thead>
      <tr>
        <th>Model Outputs</th>
        <th style="width: 90px">Default</th>
        <th style="width: 90px">Adjusted</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(m, index) in modelOutput" :key="index">
        <td>{{ m[0] }}</td>
        <td
          class="numeric-val"
          :style="{
            background: m[4] ? colorize(m[1], m[4]) : '#fff'
          }"
        >
          {{ fmtNum(m[1], 0, m[3], !m[3]) }}
        </td>
        <td
          class="numeric-val"
          :style="{
            background: m[4] ? colorize(m[2], m[4]) : '#fff'
          }"
        >
          <TweenableNum :value="m[2]" :digits="0" :isPercent="m[3]"></TweenableNum>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import "@/scss/site.scss"
import { scaleLinear } from "d3-scale"

import TweenableNum from "@/components/common/TweenableNum.vue"

export default {
  components: { TweenableNum },
  props: {
    input: { type: Object, required: true }
  },
  computed: {
    modelOutput() {
      const d = this.input
      return [
        ["Intrinsic Enterprise Value", d.intrinsicEVOriginal, d.intrinsicEV],
        ["Intrinsic Equity Value", d.intrinsicSVOriginal, d.intrinsicSV],
        ["Intrinsic Price", d.intrinsicSVOriginal / d.Shares, d.intrinsicSV / d.Shares],
        ["Current Price", d.P_CLOSE, d.P_CLOSE],
        [
          "Upside/Downside",
          d.updownOriginal,
          d.updown,
          true, //is percent
          [-0.5, 0, 2] //color scale
        ],
        [
          "IRR",
          d.IRROriginal,
          d.IRR,
          true, //is percent
          [-0.1, 0, 0.2] //color scale
        ]
      ]
    }
  },
  methods: {
    colorize(v, range) {
      if (v <= range[0]) return "#e77170"
      if (v >= range[1]) return "#7abc80"
      return scaleLinear()
        .domain(range)
        .range(["#e77170", "#dfff00", "#7abc80"])(v)
    }
  }
}
</script>

<style lang="scss" scoped>
.model-input-table {
  tr:nth-child(even) td {
    border-bottom: 1px solid #999;
  }
}
</style>
