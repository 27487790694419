<template>
  <table class="summary-table assumption-table">
    <thead>
      <tr>
        <th>DCF Inputs</th>
        <th style="width: 64px">Default</th>
        <th style="width: 64px">Override</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(m, index) in assumptionMetrics" :key="index">
        <td>{{ m.name }}</td>
        <td>{{ fmtNum(m.rawValue, m.digits, m.type === "percent") + (m.type === "multiples" ? "x" : "") }}</td>
        <td style="width: 64px" :style="{ padding: 0, 'border-bottom': m.override ? '1px solid #999' : 'none' }">
          <OverrideInput
            v-if="m.override"
            v-bind="{
              input: input,
              showInit: false,
              field: m.field,
              digits: m.digits,
              type: m.type,
              min: m.min,
              max: m.max,
              textAlign: 'center'
            }"
            @updateData="$emit('update', $event)"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import OverrideInput from "@/components/common/OverrideInput.vue"

const Metric = (input, self) =>
  class {
    constructor(field, name, type = "none", override = false, min, max) {
      this.field = field
      this.name = name
      this.type = type
      this.override = override
      this.min = min
      this.max = max
    }

    get rawValue() {
      const original = input[this.field + "Original"]
      //there is no orignal for not overridable field
      const raw = input[this.field]
      if (original !== undefined) {
        return original
      }
      return raw
    }

    //being lazy to not introduce another field
    get digits() {
      switch (this.field) {
        case "beta":
        case "Mult_BK":
          return 2
        case "TV":
        case "TVPS":
          return 0
        default:
          return 1
      }
    }

    requestUpdate($event) {
      let v = $event.target.valueAsNumber
      if (this.type === "percent") {
        v = v / 100
      }
      if (Number.isNaN(v)) {
        v = input[this.field + "Original"]
      }
      self.$emit("update", { [this.field]: v })
    }
  }

export default {
  components: { OverrideInput },
  props: {
    input: { type: Object, required: true }
  },

  data() {
    return { assumptionMetrics: [] }
  },

  mounted() {
    //Added here as the TVMult could subject to change when updating the numbers
    this.$watch("input", () => this.calcAssumptions(), { immediate: true })
  },

  methods: {
    calcAssumptions() {
      const M = Metric(this.input, this)
      this.assumptionMetrics = [
        new M("KE", "Equity Cost of Capital", "percent", true),
        new M("TVGro", "Terminal Growth Rate", "percent", true),
        new M("yld30yr", "Risk Free Rate", "percent"),
        new M("ERP", "Equity Risk Prem.", "percent"),
        new M("beta", "Beta(Equity)", "none"),
        new M("P_BK", "Price / Book Value", "percent"),
        new M("TV", "Terminal Value"),
        new M("TVPS", "Terminal Value Per Share")
      ]
    }
  }
}
</script>
