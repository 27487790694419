<template>
  <div>{{ animatedNumber }}</div>
</template>

<script>
import gsap from "gsap"
import { debounce } from "lodash"

export default {
  props: {
    value: { type: [String, Number] },
    digits: { type: Number, default: 0 },
    isPercent: { type: Boolean, default: false },
    animateLoading: { type: Boolean, default: false }
  },
  data() {
    return { tweenNum: 0 }
  },
  computed: {
    animatedNumber: function() {
      const useParent = !this.isPercent
      return this.fmtNum(this.tweenNum, this.digits, this.isPercent, useParent)
    }
  },
  mounted() {
    if (!this.animateLoading) {
      this.tweenNum = this.value
    }
    this.$watch(
      "value",
      debounce(function(v) {
        if (typeof v === "number") {
          //unable to tween from Infinity to other value
          if (this.tweenNum === Infinity || this.tweenNum === -Infinity) {
            this.tweenNum = 0
          }
          gsap.to(this.$data, { duration: 0.5, tweenNum: v })
        } else {
          this.tweenNum = v
        }
      }, 300),
      {
        immediate: this.animateLoading
      }
    )
  }
}
</script>
