<template>
  <div class="dcf-summary">
    <v-sheet elevation="1" rounded width="440">
      <table class="summary-table" v-if="scenarios">
        <thead>
          <tr>
            <th>
              Scenarios
              <span class="edit-btn" @click="showDcfSummaryDialog = true" v-if="userCanAuthor">edit</span>
            </th>
            <th class="scenario-link" v-for="s in scenarios" :key="s.override_id" @click="scenarioSelected(s)">
              {{ s.scenario_name }}
            </th>
            <th>Wgt Avg</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Probability</td>
            <td v-for="s in scenarios" :key="s.override_id">
              <TweenableNum :value="s.probability" :digits="0" :isPercent="true"></TweenableNum>
            </td>
            <td></td>
          </tr>
          <tr v-for="m in measures" :key="m.name">
            <td>{{ m.name }}</td>
            <td v-for="s in scenarios" :key="s.override_id">
              <TweenableNum :value="s[m.field]" :digits="m.digits" :isPercent="m.isPercent"></TweenableNum>
            </td>
            <td
              class="numeric-val"
              :style="{
                background: wgtAvgs[m.field] ? colorize(wgtAvgs[m.field], [0, 0.1]) : '#fff'
              }"
            >
              <TweenableNum :value="wgtAvgs[m.field]" :digits="m.digits" :isPercent="m.isPercent"></TweenableNum>
            </td>
          </tr>
        </tbody>
      </table>
    </v-sheet>
    <v-dialog v-model="showDcfSummaryDialog" width="600">
      <v-card>
        <v-card-title>Update DCF Scenarios</v-card-title>
        <v-card-text>
          <table class="summmary-table">
            <thead>
              <tr>
                <th>Scenario</th>
                <th>Overrides</th>
                <th>Probability</th>
              </tr>
            </thead>
            <tr v-for="s in formData" :key="s.override_id">
              <td>{{ s.scenario_name }}</td>
              <td>
                <select class="custom-select mr-2" style="width:400px" v-model="s.override_id">
                  <option value="">
                    <span>Select from {{ versionList.length }} Override</span>
                    <span v-if="versionList.length > 0">s</span>
                  </option>
                  <option
                    v-for="({ name, lastModified, analyst, override_id }, index) in versionList"
                    :value="override_id"
                    :key="index"
                  >
                    [{{ formatDate(lastModified) }}] - {{ name }} - {{ analyst }}
                  </option>
                </select>
              </td>
              <td>
                <div class="probability-input">
                  <v-text-field v-model="s.probability" type="number" suffix="%"></v-text-field>
                </div>
              </td>
            </tr>
          </table>

          <div class="errors" style="height:80px">
            <ul>
              <li v-for="e in errors" :key="e">
                <span class="validation-error">{{ e }}</span>
              </li>
            </ul>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showDcfSummaryDialog = false" color="secondary">
            Cancel
          </v-btn>
          <v-btn @click="save()" color="primary">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "@/scss/site.scss"
import { loadVersions, getDcfScenariosSummary, processOverride, saveScenarios } from "@/apis/override.js"
import format from "date-fns/format"
import preprocess, { applyGettersSetters } from "@/utils/dcf_data_preprocessor.js"
import TweenableNum from "@/components/common/TweenableNum.vue"
import _ from "lodash"
import { mapActions, mapGetters } from "vuex"
import { scaleLinear } from "d3-scale"

export default {
  props: {
    rawInput: { type: Object, required: true },
    id: { type: String, required: true }
  },
  components: { TweenableNum },
  data() {
    return {
      showDcfSummaryDialog: false,
      issuer_id: null,
      scenarios: [],
      versionList: [],
      errors: [],
      scenarioOrder: ["base", "bull", "bear"],
      measures: [
        { field: "updown", name: "Up/Down", isPercent: true, digits: 0 },
        { field: "IRR", name: "IRR", isPercent: true, digits: 0 }
      ],
      formData: null
    }
  },
  computed: {
    ...mapGetters("dcfStore", ["userCanAuthor"]),
    wgtAvgs: function() {
      var res = {}

      for (const m of this.measures) {
        var totalVal = 0
        var totalWgt = 0
        for (const s of this.scenarios) {
          totalVal += s[m.field] * s.probability
          totalWgt += s.probability
        }
        res[m.field] = totalVal / totalWgt
      }

      return res
    }
  },
  methods: {
    ...mapActions("dcfStore", ["setSelectedScenario"]),
    formatDate(dStr) {
      return format(new Date(dStr), "MM/dd/yyyy")
    },
    scenarioSelected(scenario) {
      this.setSelectedScenario(scenario)
    },
    colorize(v, range) {
      if (v <= range[0]) return "#e77170"
      if (v >= range[1]) return "#7abc80"
      return scaleLinear()
        .domain(range)
        .range(["#e77170", "#dfff00", "#7abc80"])(v)
    },
    updateFormData() {
      let res = _.map(this.scenarioOrder, (item, idx, arr) => {
        let obj = {
          scenario_name: item,
          override_id: null,
          probability: 0
        }
        if (this.scenarios && this.scenarios.length > 0) {
          let s = _.find(this.scenarios, { scenario_name: item })
          if (s) {
            obj.override_id = s.override_id
            obj.probability = s.probability * 100
          }
        }
        return obj
      })

      this.formData = res
    },
    validate() {
      this.errors = []
      let probabilityTotal = 0
      for (const s of this.formData) {
        probabilityTotal += parseInt(s.probability, 10)
        if (!s.override_id) {
          this.errors.push("Please select an override for the " + s.scenario_name + " scenario")
        }
      }
      if (probabilityTotal !== 100) {
        this.errors.push("The probabilities need to sum up to 100")
      }
      return this.errors.length === 0
    },
    async save() {
      if (this.validate()) {
        let issuer_id = this.issuer_id
        let scenarios = _.map(this.formData, (item) => {
          item.probability = parseInt(item.probability, 10) / 100
          item.issuer_id = issuer_id
          return item
        })
        await saveScenarios(this.QUANT_API_HOST, scenarios)
        this.update()
        this.showDcfSummaryDialog = false
      }
    },
    async update() {
      this.versionList = await loadVersions(this.QUANT_API_HOST, this.id, this.$root.user.name)
      let _scenarios = await getDcfScenariosSummary(this.QUANT_API_HOST, this.id)
      if (_scenarios && _scenarios.length > 1) {
        // reverse the actual order so that higher index is better
        let order = this.scenarioOrder.slice().reverse()
        _scenarios.sort((a, b) => {
          // names not in the order will be -1
          let aIdx = order.indexOf(a.scenario_name)
          let bIdx = order.indexOf(b.scenario_name)

          if (aIdx > bIdx) {
            return -1
          } else if (aIdx < bIdx) {
            return 1
          } else {
            return 0
          }
        })
      }

      //we need to derive each of the scenarios
      var that = this
      if (_scenarios && _scenarios.length > 0) {
        _scenarios.forEach(function(item, idx, arr) {
          let override_id = item.override_id
          let overrideData = _.find(that.versionList, { override_id: override_id })
          if (overrideData) {
            const FYACurrent = that.$root.secInfo.FYA

            var override = processOverride(overrideData, FYACurrent)
            let input = preprocess(that.rawInput)
            if (override) {
              Object.entries(override).forEach(([k, v]) => {
                that.$set(input, k, v)
              })
              let dervied = applyGettersSetters({ ...input })
              for (const m of that.measures) {
                arr[idx][m.field] = dervied[m.field]
              }
            }
          }
        })
      }

      this.scenarios = _scenarios

      this.updateFormData()
    }
  },
  async mounted() {
    this.issuer_id = this.$root.secInfo.issuer_id
    this.update()
  }
}
</script>

<style>
.probability-input .v-text-field__slot input {
  text-align: right !important;
}
</style>

<style scoped>
.dcf-summary {
  position: absolute;
  top: -105px;
  left: 700px;
  font-size: 14px;
}
.dcf-summary table td {
  padding: 3px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: right;
}
.dcf-summary table > tbody > tr:not(:last-child) > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

.dcf-summary table tr td:first-child {
  text-align: left;
  color: black;
}

.dcf-summary .highlight {
  background-color: rgb(122, 188, 128);
}

.dcf-summary .edit-btn {
  color: #014165;
  font-weight: normal;
  text-decoration: underline;
}
.dcf-summary .edit-btn:hover {
  cursor: pointer;
}

.dcf-summary .probability-input {
  width: 80px;
}

.validation-error {
  color: rgb(138, 4, 4);
}

.dcf-summary .scenario-link {
  color: #014165;
  font-weight: normal;
  text-transform: capitalize;
  text-decoration: underline;
}
.dcf-summary .scenario-link:hover {
  cursor: pointer;
}

.custom-select {
  appearance: listbox;
  background: #fff;
  border: 1px solid #999;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 28px;
  height: 28px;
  padding: 0 8px;
  border-radius: 4px;
}

.probability-input .v-text-field__slot input {
  text-align: right !important;
}
</style>
