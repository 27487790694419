<template>
  <table class="analysis-table">
    <caption>
      Upside/Downside Analysis
    </caption>
    <tbody>
      <tr v-for="(row, rindex) in 5" :key="rindex">
        <td v-if="rindex === 0" rowspan="6" class="row-group">
          <div class="group-content vertical ma-0 pa-0">Discount Rate</div>
        </td>
        <td class="discounts-steps">{{ fmtNum(input.discounts[row - 1], 0, true) }}</td>
        <td
          v-for="(col, cindex) in 5"
          :key="cindex"
          :style="{ color: '#333', background: colorize(dataArr[(row - 1) * 5 + col - 1]) }"
        >
          <TweenableNum :value="dataArr[(row - 1) * 5 + col - 1]" :isPercent="true"></TweenableNum>
        </td>
      </tr>
      <tr class="multiple-steps">
        <td></td>
        <td v-for="(col, cindex) in 5" :key="cindex">{{ fmtNum(input.multiples[col - 1], 0, false) }}x</td>
      </tr>
      <tr class="col-group">
        <td></td>
        <td colspan="6">
          <span class="group-content">Terminal EBITDA Multiple</span>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { scaleLinear } from "d3-scale"
import { interpolateRdYlGn } from "d3-scale-chromatic"
import TweenableNum from "@/components/common/TweenableNum.vue"

export default {
  components: { TweenableNum },
  props: {
    input: { type: Object, required: true }
  },
  computed: {
    dataArr() {
      return this.input.updownScenarios
    },
    lower() {
      return Math.min(...this.dataArr.filter((d) => !isNaN(d)))
    },
    upper() {
      return Math.max(...this.dataArr.filter((d) => !isNaN(d)))
    }
  },
  methods: {
    colorize(v) {
      return scaleLinear()
        .domain([this.lower, 0, this.upper])
        .range(["#e77170", "#eeeeee", "#7abc80"])(v)
    },
    colorize2(v) {
      const range = this.upper - this.lower
      return interpolateRdYlGn((v - this.lower) / range)
    }
  }
}
</script>

<style lang="scss" scoped>
.analysis-table {
  width: 100%;
  border: 1px solid #999;
  border-spacing: 0;
  table-layout: fixed;

  @media print {
    font-size: 12px;
  }

  caption {
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    border-top: 1px solid #999;
    font-family: "Roboto Slab", serif;
    font-size: 12px;
    font-weight: bold;
    background-color: #d1d1d1;
    color: #014165;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
    text-align: left;
    padding-left: 6px;
  }

  td {
    height: 32px;
    line-height: 32px;
    text-align: center;
  }

  td:first-child {
    width: 28px;
    position: relative;
  }

  tr:nth-child(3) td {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
  }
  tr:nth-child(1) td:nth-child(5) {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }
  /*handle colspan*/
  tr:not(:nth-child(1)) td:nth-child(4) {
    border-left: 1px solid #000;
    border-right: 1px solid #000;
  }

  .multiple-steps {
    font-weight: bold;
    background: #f2f2f2;
  }

  .discounts-steps {
    font-weight: bold;
    width: 48x;
    background: #f2f2f2;
  }

  tr.col-group td {
    background: #d9d9d9;
    font-weight: bold;
    font-style: italic;
    height: 28px;
    line-height: 28px;
  }

  .row-group {
    background-color: #d9d9d9;
    color: #1c1c1c;
    font-size: 13px;
    font-weight: bold;
    font-style: italic;
  }

  .group-content {
    font-family: "Roboto Slab", serif;
    font-weight: bold;
    font-size: 12px;
    &.vertical {
      white-space: nowrap;
      transform: rotate(180deg);
      writing-mode: tb-rl;
      line-height: 2;
    }
  }
}
</style>
