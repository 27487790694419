import { groupBy, orderBy, forEach } from "lodash"
import { makeAPIClient } from "@/main.js"

export async function loadVersions(host, id, username) {
  const api = await makeAPIClient(host)
  const { data: versions } = await api.get(`/valuation/GetDcfOverrides?security_id=${id}&username=${username}`)
  return versions
}

export async function saveOverride(host, overrides) {
  var postBody = Object.fromEntries(
    Object.entries(overrides).map(([k, v]) => {
      return [k.replace("Override", ""), v]
    })
  )

  if (postBody.note && postBody.note.ops.length > 0) {
    postBody.note = JSON.stringify(postBody.note)
  }
  if (postBody["Mult.EBITDA"]) {
    postBody["Mult_EBITDA"] = postBody["Mult.EBITDA"]
    delete postBody["Mult.EBITDA"]
  }
  if (postBody["eq.tcap"]) {
    postBody["eq_tcap"] = postBody["eq.tcap"]
    delete postBody["eq.tcap"]
  }

  const api = await makeAPIClient(host)
  return await api.post(`/valuation/AddUpdateOverride`, postBody)
}

export async function saveDerived(host, derived) {
  let objRes = derived
  var dt = new Date().toISOString()
  var tmp = {}
  tmp["issuer_id"] = objRes.issuer_id
  tmp["override_id"] = objRes.override_id
  tmp["security_id"] = objRes.security_id
  tmp["version"] = objRes.version
  tmp["analyst"] = objRes.analyst
  tmp["day"] = dt

  var yrs = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  var ymetrics = [
    "capxFY",
    "fcfFY",
    "opmFY",
    "revFY",
    "oprFY",
    "daFY",
    "intFY",
    "wcFY",
    "taxFY",
    "groFY",
    "taxRateFY",
    "wcOverRevFY",
    "capxOverRevFY",
    "daOverRevFY"
  ]
  ymetrics.forEach((a) => {
    yrs.forEach((y) => {
      var p = a + y
      if (objRes.hasOwnProperty(p)) {
        tmp[p] = objRes[p]
      }
    })
  })

  var pmetrics = ["EBITDAFY", "EPSFY", "OPIFY", "ROEFY", "CFROICFY", "PBFY", "PSFY", "EVoverEBITDAFY", "EVoverEPSFY"]
  var pyrs = [-3, -2, -1, 0, 1, 2, 3]

  pmetrics.forEach((a) => {
    pyrs.forEach((y) => {
      var p = a + y
      if (objRes.hasOwnProperty(p)) {
        tmp[p.replace("-", "_")] = objRes[p]
      }
    })
  })

  var metrics = [
    "WACC",
    "Mult.EBITDA",
    "Mult.Sales",
    "pctOfValueTV",
    "IntrinsicValue",
    "IntrinsicStockValue",
    "IntrinsicPrice",
    "P_CLOSE",
    "month",
    "eq.tcap",
    "Shares",
    "avg_op_10y",
    "avg_tax_10y",
    "avg_wc_sales_10y",
    "avg_capex_sales_10y",
    "rev_gr_10y_cagr",
    "currentEVOverEBITDA"
  ]
  metrics.forEach((p) => {
    if (objRes.hasOwnProperty(p)) {
      tmp[p.replace(".", "_")] = objRes[p]
    }
  })

  tmp["adjusted_intrinsic_price"] = (objRes.intrinsicEVFundamental * tmp["eq_tcap"]) / tmp.Shares

  tmp["updown"] = objRes.updown
  tmp["updown_base"] = objRes.updownOriginal

  tmp["irr"] = objRes.IRR
  tmp["irr_base"] = objRes.IRROriginal

  var postBody = { ...tmp }

  if (postBody.note && postBody.note.ops.length > 0) {
    postBody.note = JSON.stringify(postBody.note)
  }
  if (postBody["Mult.EBITDA"]) {
    postBody["Mult_EBITDA"] = postBody["Mult.EBITDA"]
    delete postBody["Mult.EBITDA"]
  }
  if (postBody["eq.tcap"]) {
    postBody["eq_tcap"] = postBody["eq.tcap"]
    delete postBody["eq.tcap"]
  }

  const api = await makeAPIClient(host)
  return await api.post(`/valuation/AddUpdateDcfDerived`, postBody)
}

export async function deleteOverride(host, override_id) {
  const api = await makeAPIClient(host)
  return await api.get(`/valuation/DeleteOverride?override_id=` + override_id)
}

export async function getDcfScenariosSummary(host, security_id) {
  const api = await makeAPIClient(host)
  const { data: scenarios } = await api.get(`/valuation/GetDcfScenariosSummary?security_id=` + security_id)
  return scenarios
}

export async function saveScenarios(host, scenarios) {
  const api = await makeAPIClient(host)
  return await api.post(`/valuation/AddUpdateScenarios`, scenarios)
}

export function processOverride(update, FYACurrent) {
  var fields = Object.keys(update)
  var overrides = {}
  var excludeFields = [
    "name",
    "version",
    "override_id",
    "scenario_name",
    "security_id",
    "day",
    "analyst",
    "FYASnapshot",
    "issuer_id",
    "note"
  ]
  fields.forEach((f) => {
    if (!excludeFields.includes(f)) {
      if (Number.isFinite(update[f])) {
        overrides[f + "Override"] = update[f]
      }
    } else {
      overrides[f] = update[f]
    }
  })

  const FYASnapshot = overrides.FYASnapshot

  if (_.isString(overrides.note)) {
    overrides.note = JSON.parse(overrides.note)
  }

  if (overrides.note === null || overrides.note === undefined) {
    overrides.note = { ops: [{ insert: "" }] }
  }

  if (FYASnapshot !== undefined && FYACurrent !== undefined) {
    const snapYear = FYASnapshot.split("-")[0]
    const curYear = FYACurrent.split("-")[0]
    const diffYear = parseInt(curYear) - parseInt(snapYear)
    const re = /(.*)FY([-+]?\d{1,2})/
    const alters = Object.keys(overrides).filter((d) => {
      const result = re.exec(d)
      if (result === null) {
        return false
      }
      const [, , yearStr] = result
      const year = parseInt(yearStr)
      // if any FY advancing results an estimate year to history, discard that
      if (year > 0 && year <= diffYear) {
        return false
      }
      return true
    })

    //group and order by FYs, to make sure the recent ones push back/left to the previous ones.
    const groups = Object.values(
      _.groupBy(alters, (d) => {
        const [, fPrefix] = re.exec(d)
        return fPrefix
      })
    )
      .map((g) =>
        _.orderBy(g, (d) => {
          const [, , yearStr] = re.exec(d)
          return parseInt(yearStr)
        })
      )
      .reduce((acc, cur) => [...acc, ...cur], [])

    groups.forEach((f) => {
      const value = overrides[f]
      const [, fPrefix, year] = re.exec(f)
      delete overrides[f]
      overrides[`${fPrefix}FY${parseInt(year) - diffYear}Override`] = value
    })

    return overrides
  }
}
