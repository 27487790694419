<template>
  <table class="summary-table ltg-table">
    <thead>
      <tr class="grouped-header-row">
        <th rowspan="2" style="width: 130px; border-right: 1px solid #999">Growth</th>
        <th colspan="2" style="border-right: 1px solid #999">5Y</th>
        <th colspan="2">10Y</th>
      </tr>
      <tr class="grouped-header-row child-cols">
        <!--stupod workaround to make the css:first-child works with rowspan -->
        <th style="display:none"></th>
        <th>Default</th>
        <th style="border-right: 1px solid #999">Adj</th>
        <th>Default</th>
        <th>Adj</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(m, index) in ltgs" :key="index">
        <td>{{ m.name }}</td>
        <td>
          <TweenableNum :value="input[m.field + '5YOriginal']" :digits="0" :isPercent="true"></TweenableNum>
        </td>
        <td>
          <TweenableNum :value="input[m.field + '5Y']" :digits="0" :isPercent="true"></TweenableNum>
        </td>
        <td>
          <TweenableNum :value="input[m.field + '10YOriginal']" :digits="0" :isPercent="true"></TweenableNum>
        </td>
        <td>
          <TweenableNum :value="input[m.field + '10Y']" :digits="0" :isPercent="true"></TweenableNum>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import TweenableNum from "@/components/common/TweenableNum.vue"

export default {
  components: { TweenableNum },
  props: {
    input: { type: Object, required: true }
  },

  data() {
    return {
      ltgs: [
        { name: "Revenue", field: "LTGRev" },
        { name: "Operating Income", field: "LTGOpr" },
        { name: "Invested Capital", field: "LTGCapx" },
        { name: "Free Cash Flow", field: "LTGFcf" }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
td,
th {
  &:not(:first-child) {
    text-align: center;
  }
}
.grouped-header-row {
  &.child-cols th {
    font-size: 11px;
  }

  th {
    &:not([rowspan]) {
      height: 16px;
      line-height: 1;
    }
    &:not([colspan]) {
      width: 32px;
    }
    &[colspan] {
      border-bottom: none;
    }
  }
}
</style>
