var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.input)?_c('table',{staticClass:"custom-table"},[_c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"200px"}}),_vm._l((_vm.fys),function(fy,order){return _c('th',{key:order,class:{ forecast: order > 5 },domProps:{"innerHTML":_vm._s(("FY" + (fy + _vm.curFY)))}})})],2)]),_vm._l((_vm.rowItems),function(row,index){
var _obj;
return [(row.override)?_c('tr',{key:("override_" + index),class:{ 'override-row': true }},[_c('td',{class:{ indented: row.child, percent: row.percent && row.name !== 'opm' },domProps:{"innerHTML":_vm._s(("<div>" + (row.label) + "</div>"))}}),_vm._l((_vm.fys),function(fy,order){return _c('td',{key:order,class:{
          percent: row.percent,
          forecast: order > 5
        }},[(row.name.startsWith('gro') && isNaN(_vm.input[((row.name) + "FY" + fy)]))?_c('div'):((row.name === 'rev' && fy >= 0) || (row.name === 'gro' && fy <= 0))?_c('TweenableNum',{style:({ marginRight: row.percent ? '14px' : 'auto' }),attrs:{"value":_vm.input[((row.name) + "FY" + fy)],"digits":row.digits,"isPercent":row.percent}}):_c('OverrideInput',_vm._b({on:{"updateData":function($event){return _vm.$emit('updateOverride', $event)}}},'OverrideInput',{
            input: _vm.input,
            field: ((row.name) + "FY" + fy),
            type: row.percent ? 'percent' : 'value',
            digits: row.digits,
            showInit: true
          },false))],1)})],2):_c('tr',{key:index,class:( _obj = {}, _obj[("row-" + (row.name))] = true, _obj )},[_c('td',{class:{ indented: row.child, percent: row.percent }},[_c('div',[_c('div',{staticClass:"d-inline-block"},[_vm._v(" "+_vm._s(row.label)+" ")])])]),_vm._l((_vm.fys),function(fy,order){return _c('td',{key:order,class:{ percent: row.percent }},[_c('TweenableNum',{attrs:{"value":_vm.input[((row.name) + "FY" + fy + (row.override ? 'Original' : ''))],"digits":row.digits,"isPercent":row.percent}})],1)})],2)]})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }