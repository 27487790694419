<template>
  <div class="dcf-tool-wrapper" @keyup.ctrl="shortCutPress">
    <div v-if="input">
      <div class="px-4" v-if="$root.secInfo.IND === 'Banks'">
        <router-link to="">view RIM DCF</router-link>
      </div>
      <div class="data-update-desc">Data updated as of {{ formatDate(input.day) }}</div>
      <Toolbar ref="dcfToolbar" :input="input" :id="id" class="d-print-none" @update="updateData" />
      <div class="d-flex py-2">
        <div class="px-1" style="width: 23%">
          <Assumptions :input="input" @update="updateData" />
        </div>
        <div class="px-1" style="width: 23%">
          <AssumptionChecks :input="input" />
          <LongTermGrowth :input="input" class="mt-2" />
        </div>
        <div class="px-1" style="width: 27%">
          <ModelOutputs :input="input" />
          <OverrideNote class="mt-2" :content="input.note" @updateNote="updateData({ reload: false, note: $event })" />
        </div>
        <div class="pl-1" style="width: 27%">
          <UpsideDownsideAnalysis :input="input" />
        </div>
      </div>
      <DCFDataTable :input="input" @updateOverride="updateData" />
    </div>

    <div class="d-print-none">
      <v-switch
        v-model="showFormulas"
        @click="setMathjax"
        :label="`${showFormulas ? 'Hide' : 'Show'} Formulas`"
      ></v-switch>
      <div v-if="showFormulas" class="d-flex space-between" v-text="dcfFormulas"></div>
    </div>

    <DCFSummary :id="id" :rawInput="rawInput" v-if="rawInput" />
  </div>
</template>

<script>
import Assumptions from "@/components/DCFTool/Assumptions.vue"
import AssumptionChecks from "@/components/DCFTool/AssumptionChecks.vue"
import DCFDataTable from "@/components/DCFTool/DCFDataTable.vue"
import ModelOutputs from "@/components/DCFTool/ModelOutputs.vue"
import LongTermGrowth from "@/components/DCFTool/LongTermGrowth.vue"
import UpsideDownsideAnalysis from "@/components/DCFTool/UpsideDownsideAnalysis.vue"
import DCFSummary from "@/components/DCFTool/DCFSummary.vue"
import Toolbar from "@/components/DCFTool/ActionToolbar.vue"
import preprocess, { applyGettersSetters } from "@/utils/dcf_data_preprocessor.js"
import OverrideNote from "@/components/DCFTool/OverrideNote.vue"
import EventBus from "@/EventBus.js"
import dcfFormulas from "!raw-loader!@/misc/dcf-formulas.tex"

export default {
  name: "DCFTool",
  props: {
    id: { type: String, requried: true }
  },
  components: {
    Toolbar,
    Assumptions,
    AssumptionChecks,
    LongTermGrowth,
    ModelOutputs,
    UpsideDownsideAnalysis,
    DCFSummary,
    DCFDataTable,
    OverrideNote
  },

  data() {
    return {
      dcfFormulas,
      showFormulas: false,
      rawInput: null,
      input: null,
      showDcfSummaryDialog: false
    }
  },

  async mounted() {
    try {
      const quantApiClient = await this.makeAPIClient(this.QUANT_API_HOST)
      const ivdataResponse = await quantApiClient.get(`/valuation/getivdata?security_id=${this.id}`)
      const ivData = ivdataResponse.data[0]

      this.rawInput = ivData
      this.input = preprocess(ivData)
    } catch (e) {
      console.error("failed to fetch valuation data")
      console.error(e)
      return
    }

    EventBus.$on("updateData", this.updateData.bind(this))

    this.updateData({ reload: true, ...{} })
    // delete hash
    history.pushState("", document.title, location.pathname + location.search)
  },

  methods: {
    setMathjax() {
      if (this.showFormulas) {
        this.$nextTick(() => {
          MathJax.typeset()
        })
      }
    },
    shortCutPress({ code }) {
      if (code === "KeyZ") {
        this.$refs.dcfToolbar.undo()
      } else if (code === "KeyY") {
        this.$refs.dcfToolbar.redo()
      }
    },
    formatDate(intDate) {
      const [, year, month, day] = /(\d{4})(\d{2})(\d{2})/.exec(intDate)
      return `${year}-${month}-${day}`
    },

    updateData($event) {
      if ($event.reload) {
        this.input = preprocess(this.rawInput)
      }
      Object.entries($event).forEach(([k, v]) => {
        this.$set(this.input, k, v)
      })
      //vue $set will not trigger object getter
      this.input = applyGettersSetters({ ...this.input })
    }
  }
}
</script>

<style>
.dcf-tool-wrapper {
  position: relative;
  font-size: 12px;
  width: 1480px;
}
</style>
