<template>
  <table class="custom-table" v-if="input">
    <caption v-if="revisionRequired" style="font-size: 12px; text-align:left; padding-left:200px">
      <div v-if="!input.fyAdjustOnly" style="color: #c25339">
        <v-icon small>mdi-alert-circle-outline</v-icon>
        Hist #s may not align with FY period, click
        <a class="pa-0" style="text-shadow: " small text @click="reviseFYRev">HERE</a>
        to shift FY then input missing FYA value.
      </div>
      <div v-else style="color: green">
        <v-icon small color="green">mdi-check</v-icon>
        Historical revenue numbers (FY-1 to FY-5) successfully shifted.
        <span style="padding: 0 4px; background:#d7d787">&nbsp;FY0 still requires manual override.</span>
      </div>
    </caption>
    <thead>
      <tr>
        <th style="width: 196px; border-right: 1px solid #000; background-color:#91969c"></th>
        <th colspan="6" class="col-group" style="background-color:#91969c">Historical</th>
        <th colspan="5" class="col-group">Stage 1 Growth</th>
        <th colspan="5" class="col-group">Stage 2 Growth</th>
      </tr>
    </thead>
    <thead>
      <tr>
        <th></th>
        <th v-for="(fy, order) in fys" :key="order" :class="{ forecast: order > 5 }" v-html="`FY${fy + curFY}`"></th>
      </tr>
    </thead>
    <template v-for="(row, index) in rowItems">
      <tr class="table-section-header" v-if="row.type === 'table-section-header'" :key="index">
        <td colspan="17">
          <div>{{ row.label }}</div>
        </td>
      </tr>
      <template v-else>
        <tr
          v-if="row.override"
          :class="{ 'override-row': true, 'row-folded': foldMe(index) }"
          :key="`override_${index}`"
        >
          <td v-if="row.foldAbove">
            <div>
              <div class="d-inline-block">
                {{ row.label }}
                <v-icon class="d-print-none" v-if="row.foldAbove" @click="$set(row, 'folded', !row.folded)">
                  mdi-{{ row.folded ? "plus" : "minus" }}-box-outline
                </v-icon>
              </div>
            </div>
          </td>
          <td
            v-else
            :class="{ indented: row.child, percent: row.percent && row.name !== 'opm' }"
            v-html="`<div>${row.label}</div>`"
          ></td>
          <td
            v-for="(fy, order) in fys"
            :key="order"
            :class="{
              percent: row.percent,
              forecast: order > 5,
              'revision-required': row.name === 'rev' && revisionRequired && fy <= 0
            }"
          >
            <div v-if="row.name.startsWith('gro') && isNaN(input[`${row.name}FY${fy}`])"></div>
            <TweenableNum
              v-else-if="(row.name === 'rev' && fy > 0) || (row.name === 'gro' && fy <= 0)"
              :value="input[`${row.name}FY${fy}`]"
              :digits="row.digits"
              :isPercent="row.percent"
            ></TweenableNum>
            <OverrideInput
              v-else
              v-bind="{
                input,
                field: `${row.name}FY${fy}`,
                type: row.percent ? 'percent' : 'value',
                digits: row.digits,
                showInit: true
              }"
              @updateData="$emit('updateOverride', $event)"
            />
          </td>
        </tr>
        <tr v-else :key="index" :class="{ [`row-${row.name}`]: true, 'row-folded': foldMe(index) }">
          <td
            :class="{
              indented: row.child,
              percent: row.percent && row.name !== 'opm'
            }"
          >
            <div>
              <div class="d-inline-block">
                {{ row.label }}
                <v-icon class="d-print-none" v-if="row.foldAbove" @click="$set(row, 'folded', !row.folded)">
                  mdi-{{ row.folded ? "plus" : "minus" }}-box-outline
                </v-icon>
              </div>
            </div>
          </td>
          <td v-for="(fy, order) in fys" :key="order" :class="{ forecast: order > 5, percent: row.percent }">
            <TweenableNum
              :value="input[`${row.name}FY${fy}${row.override ? 'Original' : ''}`]"
              :digits="row.digits"
              :isPercent="row.percent"
            ></TweenableNum>
          </td>
        </tr>
      </template>
    </template>
  </table>
</template>

<script>
import OverrideInput from "@/components/common/OverrideInput.vue"
import TweenableNum from "@/components/common/TweenableNum.vue"
export default {
  components: { OverrideInput, TweenableNum },
  props: {
    input: { type: Object, required: true }
  },
  data() {
    return {
      fys: Array(16)
        .fill(0)
        .map((d, i) => i - 5),
      rowItems: [
        { label: "Revenue", name: "rev", override: true },
        { label: "YoY Growth", name: "gro", child: true, percent: true, override: true },
        { label: "Operating Income", name: "opr" },
        { label: "YoY Growth", name: "groOpr", child: true, percent: true },
        { label: "Abs Rev YoY", name: "absRev" },
        { label: "Abs OP YoY", name: "absOp" },
        { label: "Incremental OPM", name: "incOpm", percent: true },
        { label: "Operating Margin", name: "opm", percent: true, digits: 1, override: true, foldAbove: 3 },
        { label: "Interest Expense, Net", name: "int" },
        { label: "Tax Expense", name: "tax" },
        { label: "Tax Rate", name: "taxRate", child: true, percent: true, override: true },
        { label: "NOPAT", name: "nopat", foldAbove: 3 },
        { label: "YoY Growth", name: "gronopat", child: true, percent: true },
        { label: "NOPAT Margin", name: "nopatMgn", child: true, percent: true },
        { label: "Capital Expenditures", name: "capx" },
        { label: "YoY Growth", name: "groCapx", child: true, percent: true },
        { label: "% of Sales", name: "capxOverRev", child: true, percent: true, override: true },
        { label: "Depreciation", name: "da" },
        { label: "% of Sales", name: "daOverRev", child: true, percent: true, override: true },
        { label: "Net Working Capital(Chg)", name: "wc" },
        { label: "% of Sales", name: "wcOverRev", child: true, percent: true, override: true },
        { label: "Net New Investment", name: "netNewInv", foldAbove: 8 },
        { label: "YoY Growth", name: "gronetNewInv", child: true, percent: true },
        { label: "Free Cash Flow", name: "fcf" },
        { label: "YoY Growth", name: "grofcf", child: true, percent: true, digits: 0 },
        { label: "Invested Capital Decomposition", type: "table-section-header" },
        { label: "Net Working Capital", name: "WC" },
        { label: "% of IC", name: "WCOverIC", child: true, percent: true },
        { label: "Cash & ST Investment", name: "cash", child: false, percent: false, override: true },
        { label: "% of IC", name: "cashOverIC", child: true, percent: true },
        { label: "Net PP&E", name: "PPE" },
        { label: "% of IC", name: "PPEOverIC", child: true, percent: true },
        { label: "Goodwill", name: "GW" },
        { label: "% of IC", name: "GWOverIC", child: true, percent: true },
        { label: "Other", name: "intan", override: true },
        { label: "% of IC", name: "intanOverIC", child: true, percent: true },
        { label: "Invested Capital(IC)", name: "IC", foldAbove: 10 },
        { label: "YoY Growth", name: "groIC", child: true, percent: true },
        { label: "IC x Goodwill", name: "ICxGW" },
        { label: "YoY Growth", name: "groICxGW", child: true, percent: true },
        { label: "Return on Invested Capital", type: "table-section-header" },
        { label: "ROIC", name: "ROIC", percent: true },
        { label: "ROIC x Goodwill", name: "ROICxGW", percent: true },
        { label: "ROIIC", name: "ROIIC", percent: true },
        { label: "FCF ROIC", name: "FCFROIC", percent: true }
      ]
    }
  },
  computed: {
    revisionRequired() {
      return this.$root.secInfo.FYA !== this.$root.secInfo.I_FYA
    },
    curFY() {
      if (this.$root.secInfo?.FYA) {
        return parseInt(this.$root.secInfo.FYA.split("-")[0]) - 2000
      }
      return 0
    }
  },
  beforeMount() {
    //expand rows above only for screen not printer
    const { matches } = window.matchMedia("print")
    const conditionalHideRows = this.rowItems.filter((d) => d.name === "opm" || d.name === "nopat")
    conditionalHideRows.forEach((row) => this.$set(row, "folded", !matches))
  },
  mounted() {
    window.__input__ = this.input
  },
  methods: {
    reviseFYRev() {
      const changeSet = {}
      for (var i = -5; i < 0; i++) {
        changeSet[`revFY${i}Override`] = this.input[`revFY${i + 1}Original`]
      }
      this.$emit("updateOverride", { fyAdjustOnly: true, ...changeSet })
    },
    foldMe(index) {
      const rowsAhead = this.rowItems.slice(index + 1)
      const nextFoldIndex = rowsAhead.findIndex((d) => !!d.foldAbove)
      if (nextFoldIndex < 0) return false
      return rowsAhead[nextFoldIndex].folded && nextFoldIndex < rowsAhead[nextFoldIndex].foldAbove
    }
  }
}
</script>

<style lang="scss">
.custom-table {
  width: 100%;
  table-layout: fixed;
  border: 1px solid #999;
  border-collapse: collapse;

  th {
    height: 2em;
    text-align: right;
    padding: 2px 4px;
    border-top: 1px solid #616161;
    border-bottom: 1px solid #616161;
    background: #d9d9d9;
  }

  thead:first-child th {
    height: 3em;
  }

  thead th.col-group {
    font-family: "Roboto Slab", serif;
    font-weight: 600;
    text-align: center;
    border-right: 1px solid #000;
    background: #595959;
    color: #fff;
    font-style: italic;
  }

  tr.table-section-header td {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    background-color: #808080;
    color: #fff;
    font-size: 13px;
    padding: 0 4px;
  }

  td {
    text-align: right;
    color: #000;
  }

  td:first-child {
    background: #f2f2f2;
    font-weight: 400;
    text-align: left;
    border-right: 1px solid #000;
    font-family: "Roboto Slab", serif;
  }

  th:nth-child(7),
  th:nth-child(12),
  td:nth-child(7),
  td:nth-child(12) {
    /*enforce important for printer */
    border-right: 1px solid #000;
  }

  td.percent {
    font-style: italic;
    color: #4d4f53;
  }

  td.indented {
    color: #666;
    padding-left: 24px !important;
  }

  td.revision-required {
    border: 1.5px dashed red !important;
  }

  td.forecast {
    background: #eee;
  }

  tr.row-folded {
    td {
      border-top: none;
      border-bottom: none;
    }
    td > div {
      padding: 0px 6px;
    }
  }

  tr td > div {
    max-height: 0;
    box-sizing: border-box;
    overflow: hidden;
    height: 24px;
    padding: 0px 6px;
    transition: max-height 0.3s;
  }

  tr:not(.row-folded) td > div {
    max-height: 100px;
    display: grid;
    align-items: center;
  }

  tr.row-fcf td,
  tr.row-IC td,
  tr.row-capx td {
    border-top: 2px double #3c3c3c;
  }
  td.row-opm td {
    font-style: normal !important;
  }
}
</style>
